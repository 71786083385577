<template>
  <div>
    <div
      id="userSelectModal"
      class="scrollable-modal"
    >
      <modal
        height="95%"
        name="userSelectModal"
        width="95%"
      >
        <div class="modal-content">
          <user-table/>
        </div>
        <button
          aria-label="Close modal"
          class="close-button"
          data-close
          type="button"
          @click="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </modal>
    </div>
  </div>
</template>

<script>
import UserTable from './UserTable.vue'

export default {
  components: {
    UserTable,
  },
  methods: {
    hideModal () {
      this.$modal.hide('userSelectModal')
    },
  },
}
</script>
