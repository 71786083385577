import Vue from 'vue'
import Vuex from 'vuex'
import url from '../api/url'
import urlModule from './modules/url'
import guestModule from './modules/guest'
import {getCookie} from '../processors/cookie'
import moment from 'moment'
import errorHandle from '@/processors/errorHandle'
import config from '@/config'

Vue.use(Vuex)

const state = {
  admin: {
    name: null,
  },
  availableItems: {},
  availableReservationStatuses: [],
  availableGuestTypes: [],
  table: {
    subjectType: {},
    currentTableDate: null,
    selectedTimeTableID: null,
    currentSubjectTypeID: null,
  },
  transactionTypes: [],
  historyTable: [],
  client: { logo: config.DEFAULT_LOGO_URL, reservationRules: null },
  availableSeasons: null,
  timetableExtraOptions: [],
}

const mutations = {
  FETCH_STARTUP (state, data) {
    state.admin = data.admin
    state.availableItems = data.availableItems
    state.availableReservationStatuses = data.availableReservationStatuses
    state.availableGuestTypes = data.availableGuestTypes
    // state.subjectTypes = data.subjectTypes
    Vue.set(state, 'subjectTypes', data.subjectTypes)
    Vue.set(state.table, 'currentSubjectTypeID', data.subjectTypes[0].ID)
    state.transactionTypes = data.transactionTypes
    Vue.set(state, 'client', data.client)
    state.availableSeasons = data.availableSeasons || null
    state.timetableExtraOptions = data.timetableExtraOptions || []
  },
  FETCH_TABLE (state, data) {
    state.table.subjectType = data.subjectType
    state.table.currentTableDate = data.currentTableDate
  },
  FETCH_HISTORY_TABLE (state, data) {
    Vue.set(state, 'historyTable', data)
  },
  CLEAR_HISTORY_TABLE (state) {
    Vue.set(state, 'historyTable', [])
  },
  UPDATE_CURRENT_SUBJECT_TYPE_ID (state, newSubjectTypeID) {
    Vue.set(state.table, 'currentSubjectTypeID', newSubjectTypeID)
  },
  UPDATE_TABLE_COLUMN (state, data) {
    const subjectType = data.subjectType
    const subjectFill = data.subjectFill
    const subjectID = data.subjectID
    const tableDate = data.tableDate
    const currentTableDate = state.table.currentTableDate
    let subjects, subjectIndex
    if (currentTableDate) {
      if (moment(currentTableDate).isSame(tableDate, 'day')) {
        if (state.table.subjectType.hasOwnProperty(subjectType)) {
          if (state.table.subjectType[subjectType].hasOwnProperty('subjects')) {
            subjects = state.table.subjectType[subjectType].subjects
            subjectIndex = getSubjectIndex(subjectID, subjects)
            if (subjectIndex !== false) {
              Vue.set(state.table.subjectType[subjectType].subjects[subjectIndex], 'fill', subjectFill)
            }
          }
        }
      }
    }
  },
}

const actions = {
  fetchStartUp ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.startUp,
        method: 'GET',
        params: {
          userID: getCookie('userID'),
          token: getCookie('token'),
          clientVersion: config.CURRENT_VERSION,
        },
      })
        .then((response) => {
          console.log('***********************************')
          console.log('--- StartUp data got successfully: ')
          console.log(response.body)
          console.log('***********************************')
          // console.log(response.body)
          commit('FETCH_STARTUP', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  fetchTableByDate ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getTable,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), date: data.date },
      })
        .then((response) => {
          console.log('***********************************')
          console.log('--- Fetched table ' + data.date + ' : ')
          console.log(response.body)
          console.log('***********************************')
          // this.$store.dispatch('fetchTableByDate', response.body)
          commit('FETCH_TABLE', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  fetchTimeBlockHistory ({ commit }, data) {
    const subjectID = data.subjectID
    const startDate = data.startDate

    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getTimeBlockHistory,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), subjectID: subjectID, startDate: startDate },
      }).then((response) => {
        console.log('***********************************')
        console.log('--- fetchTimeBlockHistory success: ')
        console.log(response.body)
        console.log('***********************************')
        commit('FETCH_HISTORY_TABLE', response.body)
        resolve()
      }, error => {
        errorHandle.httpError(error)
      })
    })
  },
  clearHistoryTable ({ commit }) {
    commit('CLEAR_HISTORY_TABLE')
  },
  updateSubjectTypeID ({ commit }, data) {
    commit('UPDATE_CURRENT_SUBJECT_TYPE_ID', data.newSubjectTypeID)
  },
  socket_newReservation ({ commit, state }, data) {
    console.log('socket_newReservation')
    console.log(data)
    commit('UPDATE_TABLE_COLUMN', data)
  },
  socket_resignReservation ({ commit, state }, data) {
    console.log('socket_resignReservation')
    commit('UPDATE_TABLE_COLUMN', data)
  },
  socket_newClose ({ commit, state }, data) {
    console.log('socket_newClose')
    commit('UPDATE_TABLE_COLUMN', data)
  },
  socket_removeClose ({ commit, state }, data) {
    console.log('socket_removeClose')
    commit('UPDATE_TABLE_COLUMN', data)
  },
  socket_attendReservation ({ commit, state }, data) {
    // TODO: less data possible
    console.log('socket_attendedReservation')
    commit('UPDATE_TABLE_COLUMN', data)
  },
  socket_updateReservationTableColumn ({ commit, state }, data) {
    // TODO: less data possible
    console.log('socket_updateReservationTableColumn')
    commit('UPDATE_TABLE_COLUMN', data)
  },
}

// getters are functions
const getters = {
  getTimetableExtraOptionsBySubjectType: state => (subjectTypeID) => {
    return state.timetableExtraOptions.filter(option => Number(option.subjectTypeID) === Number(subjectTypeID))
  },
  isEnabledTimetableExtraOption: state => {
    const resRules = state.client.reservationRules
    // noinspection JSUnresolvedVariable
    return resRules?.enableTimetableExtraOptions ? resRules.enableTimetableExtraOptions : false
  },
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    url: urlModule,
    guest: guestModule,
  },
})

function getSubjectIndex (subjectID, subjects) {
  let index = false
  for (let i = 0; i < subjects.length; i++) {
    if (Number(subjectID) === Number(subjects[i].ID)) {
      index = i
      break
    }
  }
  return index
}
