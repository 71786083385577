import notify from './notify'
import {logOut} from './auth'

function httpError (error) {
  const statusCode = Number(error.status)
  const msg = error.body.msg || 'Hiba'
  if (statusCode === 403) {
    logOut()
  } else if (statusCode === 520) {
    notify.error(msg)
  } else if (statusCode === 400) {
    notify.warning(msg)
  } else if (statusCode === 460) {
    location.reload(true)
  } else if (statusCode === 470) {
    window.location.replace('/#/maintenance?msg=' + encodeURIComponent(msg))
  } else if (statusCode === 0 && error.hasOwnProperty('ok') && !error.ok) {
    notify.error('A szerver pillanatnyilag nem elérhető, amennyiben 15 percen belül sem üzemel, keresd rendszergazdáinkat.')
  } else {
    // alert('unknown error')
    notify.error('Váratlan hiba, próbálja meg ismét.')
  }
}

export default {
  httpError: httpError,
}
