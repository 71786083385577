<template>
  <div class="row">
    <div class="medium-5 columns">
      <label for="availableSubjects"/>
      <select
        id="availableSubjects"
        multiple
      >
        <option
          v-for="subject in availableSubjects"
          :data-subject-name="subject.name"
          :data-subject-type="subject.type"
          :value="subject.ID"
        >
          {{ subject.type }} {{ subject.name }}
        </option>
      </select>
    </div>
    <div class="medium-2 columns">
      <button
        class="selectorBtn"
        title="Hozzáadás"
        @click="addSubject()"
      >
        <i class="fi-plus add"/>
      </button>
      <br>
      <button
        class="selectorBtn"
        title="Kivétel"
        @click="removeSubject()"
      >
        <i class="fi-minus sub"/>
      </button>
    </div>
    <div class="medium-5 columns">
      <label for="selectedSubjects"/>
      <select
        id="selectedSubjects"
        multiple
      >
        <option
          v-for="subject in selectedSubjects"
          :data-subject-name="subject.name"
          :data-subject-type="subject.type"
          :value="subject.ID"
        >
          {{ subject.type }} {{ subject.name }}
        </option>
      </select>
    </div>
    <div class="medium-12 columns">
      <span
        v-if="!isSubjectValid"
        class="form-error is-visible"
      >Legalább egy pályát ki kell választani</span>
    </div>
    <!--
    <div class="medium-12 columns">
      <button class="button" @click="addSubject()">Kiválaszt</button>
      <button class="button warning" @click="removeSubject()">Töröl</button>
    </div>
    -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      subjects: [],
      selectedSubjects: [],
    }
  },
  computed: {
    availableSubjects () {
      const subjects = this.subjects
      const selectedSubjects = this.selectedSubjects
      const availableSubjects = []
      let isSelected = false
      for (let i = 0; i < subjects.length; i++) {
        for (let j = 0; j < selectedSubjects.length; j++) {
          if (Number(subjects[i].ID) === Number(selectedSubjects[j].ID)) {
            isSelected = true
            break
          }
        }
        if (!isSelected) {
          availableSubjects.push(subjects[i])
        }
        isSelected = false
      }
      return availableSubjects
    },
    isSubjectValid () {
      return this.selectedSubjects.length > 0
    },
  },
  created: function () {
    const subjectTypes = this.$store.state.subjectTypes
    let oneTypeSubjects

    for (let i = 0; i < subjectTypes.length; i++) {
      oneTypeSubjects = subjectTypes[i].subjects
      for (let j = 0; j < oneTypeSubjects.length; j++) {
        oneTypeSubjects[j].type = subjectTypes[i].name
      }
      this.subjects = this.subjects.concat(oneTypeSubjects)
    }
  },
  methods: {
    addSubject () {
      const newSelectedSubjects = []
      const selectElem = document.getElementById('availableSubjects')
      const options = selectElem && selectElem.options
      let opt

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        if (opt.selected) {
          newSelectedSubjects.push({
            ID: opt.value,
            name: opt.getAttribute('data-subject-name'),
            type: opt.getAttribute('data-subject-type'),
          })
        }
      }
      this.selectedSubjects = this.selectedSubjects.concat(newSelectedSubjects)
    },
    removeSubject () {
      const newSelectedSubjects = []
      const selectElem = document.getElementById('selectedSubjects')
      const options = selectElem && selectElem.options
      let opt

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        if (!opt.selected) {
          newSelectedSubjects.push({
            ID: opt.value,
            name: opt.getAttribute('data-subject-name'),
            type: opt.getAttribute('data-subject-type'),
          })
        }
      }
      this.selectedSubjects = newSelectedSubjects
    },
  },
}
</script>

<style scoped>
</style>
