<template>
  <div>
    <table class="hover">
      <thead>
      <tr>
        <th class="text-center">
          Pultos
        </th>
        <th class="text-center">
          Vendég
        </th>
        <th class="text-center">
          Összeg
        </th>
        <th class="text-center">
          Létrehozva
        </th>
        <th
          v-if="isEnableLocalBankCardPayment"
          class="text-center"
        >
          Bankkártya
        </th>
      </tr>
      </thead>
      <tfoot>
      <tr>
        <td>Lezárva:</td>
        <td v-if="closedDate">
          {{ getFormattedDateTime(closedDate) }}
        </td>
        <td v-else>
          -
        </td>
        <td>Összeg: {{ income }} Ft</td>
        <td>Készpénz: {{ Number(income) - Number(cardIncome) }}</td>
        <td>Kártya: {{ cardIncome }}</td>
      </tr>
      </tfoot>
      <tbody>
      <tr
        v-for="tillRow in tillRows"
        :class="{rowReverted: tillRow.status === 'cancelled'}"
      >
        <td>{{ tillRow.invokerName }}</td>
        <td>{{ tillRow.guestName }}</td>
        <td>{{ tillRow.payment }} Ft</td>
        <td>{{ getFormattedDateTime(tillRow.createdAt) }}</td>
        <td v-if="isEnableLocalBankCardPayment">
          {{ convertBooleanToText(tillRow.isLocalBankCardPayment) }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// eslint-disable-next-line
import {convertBooleanToText, getFormattedDateTime} from '../../../../processors/util'

export default {
  props: ['tillRows', 'closedDate', 'income', 'cardIncome'],
  computed: {
    isEnableLocalBankCardPayment () {
      return this.$store.state.client.hasOwnProperty('reservationRules') &&
        this.$store.state.client.reservationRules &&
        this.$store.state.client.reservationRules.hasOwnProperty('enableLocalBankCardPayment') &&
        this.$store.state.client.reservationRules.enableLocalBankCardPayment
    },
  },
  methods: {
    getFormattedDateTime: getFormattedDateTime,
    convertBooleanToText: convertBooleanToText,
  },
}
</script>

<style scoped>
.rowReverted {
  text-decoration: line-through;
}
</style>
