const config = {
  //serverDomain: 'https://api.foglaljpalyat.hu:9007', // test server
  //serverDomain: 'https://api.foglaljpalyat.hu:39006', // demo server
  serverDomain: 'https://api.foglaljpalyat.hu:29006', // live server
  //serverDomain: 'https://api.foglalas.coimbraits.hu:9007', // foglalas test server
  //serverDomain: 'https://api.foglalas.coimbraits.hu:29007', // foglalas live server

  //serverDomain: 'https://localhost:9010', // local test
  //serverDomain: 'https://87.229.70.30:29006', // civ08 via tunel
  tokenCookieName: 'token',
}

// current client version
config.CURRENT_VERSION = 26
config.DEFAULT_LOGO_URL = 'https://foglaljpalyat.hu/img/foglaljpalyat_logo.png'

export default config
