<template>
  <div
    id="userReservationManageModal"
    class="scrollable-modal"
  >
    <modal
      height="90%"
      name="userReservationManageModal"
      width="80%"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Felhasználó foglalásai
        </h5>
        <div class="row">
          <!-- table filters -->
          <!-- start date filter -->
          <div class="medium-4 columns">
            <label>
              Ettől
              <input
                v-model="startDateFilter"
                type="date"
              >
            </label>
          </div>
          <!-- ./start date filter -->
          <!-- end date filter -->
          <div class="medium-4 columns">
            <label>
              Eddig
              <input
                v-model="endDateFilter"
                type="date"
              >
            </label>
          </div>
          <!-- ./end date filter -->
          <!-- status filter -->
          <div class="medium-4 columns">
            <label>
              Státusz
              <select
                id="statusFilter"
                v-model="statusFilter"
                class="option"
                name="statusFilter"
              >
                <option
                  v-for="status in statuses"
                  :value="status.key"
                >{{ status.name }}
                </option>
              </select>
            </label>
          </div>
          <!-- ./status filter -->
          <!-- ./table filters -->
        </div>
        <!-- table -->
        <table class="hover">
          <thead>
          <tr>
            <th>Pálya</th>
            <th>Mikor</th>
            <th>Állandó foglalás</th>
            <th>Státusz</th>
            <th>Szerkesztés</th>
          </tr>
          </thead>
          <tbody v-if="isSelectedGuest">
          <tr v-for="res in currentGuestFilteredReservations">
            <td>{{ getFormattedSubject(res.subject.typeName, res.subject.name) }}</td>
            <td>{{ getFormattedDate(res.time.date) }}: {{ getFormattedTime(res.time.start) }} -
              {{ getFormattedTime(res.time.end) }}
            </td>
            <td v-if="res.isPermanent === 1">
              Igen
            </td>
            <td v-else>
              Nem
            </td>
            <td>{{ getStatusName(res.status) }}</td>
            <td>
              <i
                v-show="res.status === 'reserved'"
                class="fi-pencil"
                @click="editReservation(res)"
              />
            </td>
          </tr>
          <tr v-show="currentGuestFilteredReservations.length <= 0">
            <td>Nincs ilyen aktív foglalás</td>
          </tr>
          </tbody>
          <tbody v-else>
          Nincs vendég kiválasztva
          </tbody>
        </table>
        <!-- ./table -->
      </div>
      <button
        aria-label="Close modal"
        class="close-button"
        data-close
        type="button"
        @click="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import {getFormattedDate, getFormattedTime} from '../../../../processors/util'

export default {
  data () {
    return {
      statusFilter: 'all',
      startDateFilter: moment().format('YYYY-MM-DD'),
      endDateFilter: null,
    }
  },
  computed: {
    currentGuestFilteredReservations () {
      const filteredReservations = []
      let allReservations, res, isItemFilterPassed
      const statusFilter = this.statusFilter
      const startDateFilter = this.startDateFilter
      const endDateFilter = this.endDateFilter
      const isFilter = {
        status: statusFilter !== 'all',
        startDate: moment(startDateFilter, 'YYYY-MM-DD', true).isValid(),
        endDate: moment(endDateFilter, 'YYYY-MM-DD', true).isValid(),
      }

      if (this.$store.state.guest.current.hasOwnProperty('allReservation')) {
        allReservations = this.$store.state.guest.current.allReservation
        for (let i = 0; i < allReservations.length; i++) {
          isItemFilterPassed = true
          res = allReservations[i]
          // status filter
          if (isFilter.status) {
            if (res.status !== statusFilter) {
              isItemFilterPassed = false
            }
          }
          // startDate filter
          if (isFilter.startDate) {
            if (moment(startDateFilter).isAfter(res.time.start)) {
              isItemFilterPassed = false
            }
          }
          // endDate filter
          if (isFilter.endDate) {
            if (moment(endDateFilter).add(1, 'day').isBefore(res.time.start)) {
              isItemFilterPassed = false
            }
          }
          // add if it's passed on filters
          if (isItemFilterPassed) {
            filteredReservations.push(res)
          }
        }

        return filteredReservations
      } else {
        return []
      }
    },
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
    statuses () {
      return this.$store.state.availableReservationStatuses
    },
  },
  methods: {
    closeModal () {
      this.$modal.hide('userReservationManageModal')
    },
    getFormattedDate: getFormattedDate,
    getFormattedTime: getFormattedTime,
    getFormattedSubject (subjectType, subject) {
      return subjectType + '/' + subject
    },
    editReservation (res) {
      const reservation = {
        status: res.status,
        startTime: res.time.start,
        endTime: res.time.end,
        length: res.time.length,
        subjectID: res.subject.ID,
        timeTableID: res.timeTableID,
        periodID: res.periodID,
        subjectTypeID: res.subject.typeID,
        isPermanent: res.isPermanent,
        participants: res.participants,
        ticketID: res.ticketID,
      }
      console.log('--- reservation on edit: ')
      console.log(reservation)
      // TODO: refresh reservations & not hide if i want to edit it
      this.$modal.hide('userReservationManageModal')
      this.$modal.show('editReservationModal', { reservation: reservation })
    },
    getStatusName (statusKey) {
      // alert(statusKey)
      let statusName = ''
      const statuses = this.statuses

      for (let i = 0; i < statuses.length; i++) {
        const item = statuses[i]
        if (item.key === statusKey) {
          statusName = item.name
          break
        }
      }

      return statusName
    },
  },
}
</script>
