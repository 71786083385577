import config from '../config'

export default {
  logIn: config.serverDomain + '/api/auth/admin',
  logOut: config.serverDomain + '/api/auth/logout',
  startUp: config.serverDomain + '/api/startup',
  getTable: config.serverDomain + '/api/tablebydate',
  getAllGuest: config.serverDomain + '/api/allguest',
  getGuestByID: config.serverDomain + '/api/guestbyid',
  getGuestAllPass: config.serverDomain + '/api/passesbyguest',
  getGuestAllReservation: config.serverDomain + '/api/reservationsbyguest',
  updateGuest: config.serverDomain + '/api/guestbyid',
  reservation: config.serverDomain + '/api/reservation',
  resign: config.serverDomain + '/api/resign',
  usePass: config.serverDomain + '/api/usepass',
  addParticipants: config.serverDomain + '/api/participants',
  passes: config.serverDomain + '/api/passes',
  buy: config.serverDomain + '/api/buy',
  closeReservation: config.serverDomain + '/api/closereservations',
  openReservation: config.serverDomain + '/api/openreservations',
  closeReservationCheck: config.serverDomain + '/api/closecheck',
  getClose: config.serverDomain + '/api/closedreservationsbyyear',
  newGuest: config.serverDomain + '/api/guest',
  diaryByDay: config.serverDomain + '/api/journalbyday',
  diaryByYearByGuest: config.serverDomain + '/api/journalbyguest',
  transactionRevert: config.serverDomain + '/api/revert',
  getTill: config.serverDomain + '/api/cassabyadminanddate',
  closeTill: config.serverDomain + '/api/closecassabyadmin',
  getTimeBlockHistory: config.serverDomain + '/api/timetableblockhistory',
  getCashFlow: config.serverDomain + '/api/guestcashflow',
  getTransactionRowIDsForAttended: config.serverDomain + '/api/transactionrowidsforattended',
  emailConfirmationResend: config.serverDomain + '/api/resendconfirmation',
  newPwdSend: config.serverDomain + '/api/newpasswordemail',
  globalComments: config.serverDomain + '/api/globalcomments',
  changeEmail: config.serverDomain + '/api/change_email_request',
  dailyReport: config.serverDomain + '/api/dailyreport',
  isUnderMaintenance: config.serverDomain + '/api/admin/is_under_maintenance',
  getStatistic: config.serverDomain + '/api/statistic',
  attendPayedReservation: config.serverDomain + '/api/attend_payed_reservation',
  getRuleList: config.serverDomain + '/api/rules',
  updateRule: config.serverDomain + '/api/rules',
  getPeriodList: config.serverDomain + '/api/periods',
}
