<template>
  <div
    id="editReservationModal"
    class="scrollable-modal"
  >
    <modal
      height="650px"
      name="editReservationModal"
      width="680px"
      @closed="modelClosed"
      @before-open="beforeOpen"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Foglalás kezelése
        </h5>
        <div class="row">
          <!-- reservation details -->
          <div class="medium-12 columns">
            <table class="unstriped">
              <tbody>
              <tr>
                <td>Név</td>
                <td>{{ currentGuest.name }}</td>
              </tr>
              <tr>
                <td>Felhasználó típus</td>
                <td>{{ getGuestTypeName(currentGuest.guestType) }}</td>
              </tr>
              <tr>
                <td>Kezdés</td>
                <td>{{ getFormattedStartTime(reservation.startTime) }}</td>
              </tr>
              <tr>
                <td>Hossz</td>
                <td>{{ reservation.length }} perc</td>
              </tr>
              <tr>
                <td>Pálya</td>
                <td>{{ getSubjectText(reservation.subjectID) }}</td>
              </tr>
              <tr>
                <td>Foglalási kód</td>
                <td>{{ reservation.reservationCode }}</td>
              </tr>
              <tr v-if="getBoughtItems.length > 0">
                <td>Vásárolt termékek</td>
                <td>
                  <ul style="margin: 0; list-style: none;">
                    <li v-for="row of getBoughtItems">
                      {{ `${row.itemName } ${row.itemQuantity}db ${getFormattedCurrency(row.itemPrice)}/db` }}
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- ./reservation details -->
          <!-- timetable extra options -->
          <div v-if="reservation.extraOptions && reservation.extraOptions.length > 0"
               class="medium-12 columns">
            <h6 class="text-center">Opciók</h6>
            <timetable-extra-options :extra-options="reservation.extraOptions"
                                     :is-editable="false"/>
          </div>
          <!-- ./timetable extra options -->
          <!-- Participants -->
          <!--
          <div class="medium-12 columns">
            <label>Résztvevők mutatása/hozzáadása
              <input id="isParticipantModuleVisible" type="checkbox" v-model="isParticipantModuleVisible">
            </label>
            <div v-if="isParticipantModuleVisible">
              <add-reservation-participant :participants="currentParticipants"></add-reservation-participant>
              <div class="row">
                <div class="medium-12 columns text-center">
                  <button class="button warning"
                          @click="modifyParticipants()">Résztvevők módosításának mentése</button>
                </div>
              </div>
            </div>
          </div>
          -->
          <!-- ./ Participants -->
          <!--
          <div class="medium-6 columns">Név: {{ currentGuest.name }}</div>
          <div class="medium-6 columns">Felhasználó típus{{ currentGuest.guestType }}</div>
          <div class="medium-6 columns">SubjectID: {{ reservation.subjectID }}</div>
          <div class="medium-6 columns">From-to: {{ reservation.startTime }} - {{ reservation.endTime }}</div>
          -->
          <!-- Move reservation -->
          <div
            v-if="reservation.status !== 'payment_started' && reservation.status !== 'payed'"
            class="medium-12 columns top-border-separator"
          >
            <div class="row">
              <div class="medium-6 columns">
                <div class="row collapse">
                  <div class="small-2 columns">
                    <label
                      class="right move-reservation-label"
                      for="moveReservationDate"
                    >Idő</label>
                  </div>
                  <div class="small-10 columns">
                    <input
                      id="moveReservationDate"
                      v-model="moveReservationData.startTime"
                      type="datetime-local"
                    >
                  </div>
                </div>
              </div>
              <div class="medium-3 columns">
                <div class="row collapse">
                  <div class="small-6 columns">
                    <label
                      class="right move-reservation-label"
                      for="moveReservationSubjectID"
                    >Pálya</label>
                  </div>
                  <div class="small-6 columns">
                    <select
                      id="moveReservationSubjectID"
                      v-model="moveReservationData.subjectID"
                    >
                      <option
                        v-for="subject in availableSubjects"
                        :value="subject.ID"
                      >
                        {{ subject.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="medium-3 columns">
                <button
                  class="button secondary right-button"
                  @click="moveReservation()"
                >
                  Áthelyezés
                </button>
              </div>
            </div>
          </div>

          <!-- ./ Move reservation -->
          <!-- Resign -->
          <div class="medium-12 columns top-border-separator">
            <div class="row">
              <div class="medium-6 columns">
                <label for="resignSelect"/>
                <select id="resignSelect">
                  <option
                    v-for="resOption in resignOptions"
                    :value="resOption.value"
                  >
                    {{ resOption.name }}
                  </option>
                </select>
              </div>
              <div class="medium-6 columns">
                <button
                  class="button secondary right-button"
                  @click="resignReservation()"
                >
                  Lemondás
                </button>
              </div>
            </div>
          </div>
          <!-- ./ Resign -->
          <!-- Pay with pass -->
          <div
            v-if="Number(reservation.length) >= timeUnitMins && reservation.status !== 'payment_started' && reservation.status !== 'payed'"
            class="medium-12 columns top-border-separator"
          >
            <div v-if="usablePasses.length > 0 && !reservation.isClub">
              <div class="row align-right">
                <div class="medium-6 columns">
                  <label for="selectPassForInstantCheckout"/>
                  <select id="selectPassForInstantCheckout">
                    <option
                      v-for="pass in usablePasses"
                      :value="pass.ID"
                    >
                      {{ pass.name }} {{ pass.currentOccasion }}/{{ pass.occasion }}
                    </option>
                  </select>
                </div>
                <div class="medium-6 columns">
                  <span>{{ reservation.length / timeUnitMins }} alkalom</span>
                  <button
                    class="button right-button"
                    @click="payWithCurrentPass()"
                  >
                    Fizetés bérlettel
                  </button>
                </div>
              </div>
            </div>
            <div
              v-else
              class="callout warning text-center"
            >
              Nincs felhasználható bérlet az alkalomhoz
            </div>
          </div>
          <!-- ./ Pay with pass -->
          <!-- Already payed or online payment started alert -->
          <div
            v-if="reservation.status === 'payment_started'"
            class="callout warning text-center medium-12 columns"
          >
            A felhasználó Online elindította a foglalás kifizetését, de még nem fejezte be, vagy félbehagyta a fizetést
          </div>
          <div
            v-if="reservation.status === 'payed'"
            class="callout success text-center medium-12 columns"
          >
            Ez az foglalás már Online ki lett fizetve
          </div>
          <!-- ./Already payed or online payment started alert -->
          <!-- history table -->
          <div
            v-if="isShowHistoryTable"
            class="medium-12 columns"
          >
            <h5 class="text-center bottom-border-separator">
              Pálya történet
            </h5>
            <time-block-history-table v-if="hasHistoryTable"/>
            <div
              v-else
              class="callout warning"
            >
              Nincs adat az időpontról
            </div>
          </div>
          <!-- ./history table -->
          <div class="medium-12 columns text-right top-border-separator">
            <button
              class="button"
              title="Vásárlás"
              @click="showOtherItemBuy()"
            >
              <i class="fi-shopping-cart"/>
            </button>
            <button
              class="button"
              title="Bérlet vásárlás"
              @click="showPassBuy()"
            >
              <i class="fi-ticket"/>
            </button>
            <button
              v-if="reservation.status !== 'payment_started' && reservation.status !== 'payed'"
              class="button"
              @click="payWithNewPass(reservation.ticketID, reservation.timeTableID, reservation.isClub)"
            >
              Fizetés alkalmi jeggyel
            </button>
            <button
              v-if="reservation.status === 'payed'"
              class="button success"
              @click="attendPayedReservation()"
            >
              Megjelent Fizetett foglaláson
            </button>
            <button
              class="button secondary"
              @click="showHistoryTable()"
            >
              Pálya történet megjelenítése
            </button>
            <button
              class="button secondary"
              @click="cancelEditReservation()"
            >
              Kilépés
            </button>
          </div>
        </div>
      </div>
      <button
        aria-label="Close modal"
        class="close-button"
        data-close
        type="button"
        @click="cancelEditReservation()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import AddReservationParticipant from './AddReservationParticipant.vue'
import TimeBlockHistoryTable from './TimeBlockHistoryTable.vue'
import TimetableExtraOptions from '@/components/views/reservation/components/schedule/components/TimetableExtraOptions';
import {getCookie} from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import moment from 'moment'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AddReservationParticipant,
    TimeBlockHistoryTable,
    TimetableExtraOptions,
  },
  data () {
    return {
      reservation: {
        status: null,
        startTime: null,
        endTime: null,
        length: null,
        subjectID: null,
        timeTableID: null,
        periodID: null,
        subjectTypeID: null,
        isPermanent: null,
        participants: null,
        ticketID: null,
        isClub: null,
        extraOptions: null,
        reservationCode: null,
        transactionRows: null,
      },
      isParticipantModuleVisible: false,
      moveReservationData: {
        startTime: null,
        subjectID: null,
      },
      isShowHistoryTable: false,
    }
  },
  computed: {
    getBoughtItems () {
      const reservation = this.reservation
      if (Array.isArray(reservation.transactionRows)) {
        return reservation.transactionRows.filter(r => r.transactionType === 'other_buy')
      } else {
        return []
      }
    },
    hasHistoryTable () {
      const historyTable = this.$store.state.historyTable
      return historyTable.length > 0
    },
    currentGuest () {
      return this.$store.state.guest.current
    },
    usablePasses () {
      // get only passes what can i use in this time interval
      const usablePasses = []
      const passes = this.$store.state.guest.current.passes
      let pass
      const subjectTypeID = this.reservation.subjectTypeID
      const periodID = this.reservation.periodID
      for (let i = 0; i < passes.length; i++) {
        pass = passes[i]
        if (pass.usability.hasOwnProperty(subjectTypeID)) {
          if (pass.usability[subjectTypeID].includes(periodID)) {
            if (pass.hasOwnProperty('validUntil')) {
              if (moment(pass.validUntil).isSameOrAfter(moment())) {
                usablePasses.push(pass)
              }
            } else {
              usablePasses.push(pass)
            }
          }
        }
      }
      return usablePasses
    },
    resignOptions: function () {
      const isPermanent = this.reservation.isPermanent
      const resOptions = [
        { name: 'Normál', value: 'normal' },
        { name: 'Nem jött el', value: 'missed' },
        { name: 'Rendkívüli', value: 'free' },
      ]

      if (isPermanent) {
        resOptions.push({ name: 'Állandó foglalás lemondása', value: 'permanent' })
      }
      return resOptions
    },
    availableSubjects () {
      let currentSubjectTypeIndex = null
      const currentSubjectTypeID = this.$store.state.table.currentSubjectTypeID
      let subjectTypes
      let subjectType

      if (this.$store.state.hasOwnProperty('subjectTypes')) {
        subjectTypes = this.$store.state.subjectTypes
        for (let i = 0; i < subjectTypes.length; i++) {
          subjectType = subjectTypes[i]

          if (Number(subjectType.ID) === Number(currentSubjectTypeID)) {
            currentSubjectTypeIndex = i
          }
        }
      }
      if (currentSubjectTypeIndex !== null) {
        return subjectTypes[currentSubjectTypeIndex].subjects
      } else {
        return []
      }
    },
    currentParticipants () {
      if (this.reservation.participants) {
        return this.reservation.participants
      } else {
        return []
      }
    },
    timeUnitMins () {
      const subjectTypeID = this.reservation.subjectTypeID
      const subjects = this.$store.state.subjectTypes;
      let subject;
      if (subjectTypeID > 0 && subjects) {
        let subject = subjects.find(t => t.ID == subjectTypeID);
        if (subject)
          return subject.timeUnitMins;
      }
      return null;
    },
  },
  methods: {
    showHistoryTable () {
      const subjectID = this.reservation.subjectID
      const startDate = moment(this.reservation.startTime).format('YYYY-MM-DD HH:mm')
      this.$store.dispatch('fetchTimeBlockHistory', { subjectID: subjectID, startDate: startDate }).then(() => {
        this.isShowHistoryTable = true
      })
    },
    getFormattedStartTime (startTime) {
      return moment(startTime).format('YYYY.MM.DD HH:mm')
    },
    getFormattedCurrency (formatMe) {
      return new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        maximumSignificantDigits: 3,
      }).format(formatMe)
    },
    getSubjectText (subjectID) {
      const subjectTypes = this.$store.state.subjectTypes
      let subjectText = ''
      if (subjectID) {
        for (let i = 0; i < subjectTypes.length; i++) {
          const subjectType = subjectTypes[i]
          for (let j = 0; j < subjectType.subjects.length; j++) {
            const subject = subjectType.subjects[j]
            if (Number(subjectID) === Number(subject.ID)) {
              subjectText = '' + subjectType.name + ' ' + subject.name
              break
            }
          }
        }
      }
      return subjectText
    },
    getGuestTypeName (guestTypeKey) {
      let guestTypeName = ''
      const guestTypes = this.$store.state.availableGuestTypes
      if (guestTypeKey) {
        for (let i = 0; i < guestTypes.length; i++) {
          const guestType = guestTypes[i]
          if (guestType.key === guestTypeKey) {
            guestTypeName = guestType.name
            break
          }
        }
      }
      return guestTypeName
    },
    beforeOpen (event) {
      const reservation = event.params.reservation
      //
      this.reservation.status = reservation.status
      this.reservation.startTime = reservation.startTime
      this.reservation.endTime = reservation.endTime
      this.reservation.length = reservation.length
      this.reservation.subjectID = reservation.subjectID
      this.reservation.timeTableID = reservation.timeTableID
      this.reservation.periodID = reservation.periodID
      this.reservation.subjectTypeID = reservation.subjectTypeID
      this.reservation.isPermanent = reservation.isPermanent
      this.reservation.participants = reservation.participants
      this.reservation.ticketID = reservation.ticketID
      this.reservation.isClub = reservation.isClub
      this.reservation.extraOptions = reservation.extraOptions
      this.reservation.reservationCode = reservation.reservationCode
      this.reservation.transactionRows = reservation.transactionRows
      //
      this.isParticipantModuleVisible = reservation.participants.length > 0
      //
      this.moveReservationData.startTime = moment(reservation.startTime).format('YYYY-MM-DDTHH:mm')
      this.moveReservationData.subjectID = reservation.subjectID
    },
    modelClosed () {
      this.reservation = {
        status: null,
        startTime: null,
        endTime: null,
        length: null,
        subjectID: null,
        timeTableID: null,
        periodID: null,
        subjectTypeID: null,
        isPermanent: null,
        participants: null,
        ticketID: null,
        isClub: null,
        extraOptions: null,
        reservationCode: null,
        transactionRows: null,
      }
      this.isParticipantModuleVisible = false
      //
      this.moveReservationData.startTime = null
      this.moveReservationData.subjectID = null
      this.isShowHistoryTable = false
    },
    cancelEditReservation () {
      this.$modal.hide('editReservationModal')
    },
    resignReservation () {
      const resignData = {
        timeTableID: this.reservation.timeTableID,
        invokerID: this.$store.state.admin.ID,
        type: document.getElementById('resignSelect').value,
      }

      console.log('***********************************')
      console.log('--- New Resign: ')
      console.log(resignData)
      console.log('***********************************')

      this.$http({
        url: this.$store.state.url.resign,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: resignData }),
      }).then(response => {
        notify.success(response.body.msg)
        console.log('---resign success')
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('editReservationModal')
      })
    },
    attendPayedReservation () {
      const attendData = {
        timeTableID: this.reservation.timeTableID,
        guestID: this.currentGuest.ID,
        reservationStart: this.reservation.startTime,
        reservationEnd: this.reservation.endTime,
        invokerID: this.$store.state.admin.ID,
      }

      console.log('***********************************')
      console.log('--- New Attend Payed Reservation: ')
      console.log(attendData)
      console.log('***********************************')

      this.$http({
        url: this.$store.state.url.attendPayedReservation,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: attendData }),
      }).then(response => {
        notify.success(response.body.msg)
        console.log('---attend success')
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('editReservationModal')
      })
    },
    moveReservation () {
      const moveData = {
        timeTableID: this.reservation.timeTableID,
        invokerID: this.$store.state.admin.ID,
        newFrom: document.getElementById('moveReservationDate').value,
        subjectID: document.getElementById('moveReservationSubjectID').value,
      }

      console.log('--- move data: ')
      console.log(moveData)

      this.$http({
        url: this.$store.state.url.reservation,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: moveData }),
      }).then(response => {
        notify.success(response.body.msg)
        console.log('--- move reservation success')
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('editReservationModal')
      })
    },
    payWithCurrentPass () {
      const payData = {
        guestID: this.$store.state.guest.current.ID,
        invokerID: this.$store.state.admin.ID,
        usedSingleItem: {
          purchasedPassID: document.getElementById('selectPassForInstantCheckout').value,
          timeTableID: this.reservation.timeTableID,
          duration: this.reservation.length,
        },
      }

      console.log('--- pay with current pass: ')
      console.log(payData)

      this.$http({
        url: this.$store.state.url.usePass,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: payData }),
      }).then(response => {
        notify.success(response.body.msg)
        this.$modal.show('userPurchaseModal', { isTicket: false, isOtherItemBuy: true, isPassBuy: false })
        console.log('--- use current pass is success')
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('editReservationModal')
      })
    },
    showOtherItemBuy () {
      this.$modal.hide('editReservationModal')
      this.$modal.show('userPurchaseModal', { isTicket: false, isOtherItemBuy: true, isPassBuy: false })
    },
    showPassBuy () {
      this.$modal.hide('editReservationModal')
      this.$modal.show('userPurchaseModal', { isTicket: false, isOtherItemBuy: false, isPassBuy: true })
    },
    modifyParticipants () {
      const participants = {
        invokerID: this.$store.state.admin.ID,
        timeTableID: this.reservation.timeTableID,
        participants: [],
      }

      const currentParticipantsElem = document.getElementById('currentParticipants')
      const options = currentParticipantsElem && currentParticipantsElem.options
      for (let i = 0; i < options.length; i++) {
        const opt = options[i]
        participants.participants.push({ guestID: opt.value })
      }

      console.log('--- modify participants: ')
      console.log(participants)

      this.$http({
        url: this.$store.state.url.addParticipants,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: participants }),
      }).then(response => {
        notify.success(response.body.msg)
        console.log('--- modify participants success')
      }, error => {
        errorHandle.httpError(error)
      })
    },
    payWithNewPass (ticketID, timeTableID, isClub) {
      const resLength = this.reservation.length
      this.$modal.hide('editReservationModal')
      this.$modal.show('userPurchaseModal',
        {
          ticketID: ticketID,
          isTicket: true,
          timeTableID: timeTableID,
          length: resLength,
          isOtherItemBuy: true,
          isPassBuy: false,
          isClub: isClub,
        },
      )
    },
  },
}
</script>

<style scoped>
.callout {
  padding: 1rem;
}

.right-button {
  position: absolute;
  right: 30px;
}

.move-reservation-label {
  margin-top: 10%;
}
</style>
