<template>
  <div class="row expanded collapse">
    <div class="medium-1 columns border-helper">
      <left-menu/>
    </div>
    <div class="medium-11 columns text-center border-helper">
      <section>
        <diary-table
          :diary-data="diaryTransactions"
          @fetchDiaryByDay="fetchDiaryByDay"
          @fetchDiaryByYearByGuest="fetchDiaryByYearByGuest"
        />
      </section>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../left_menu/LeftMenu.vue'
import DiaryTable from './components/DiaryTable.vue'
import {getCookie} from '../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
// import notify from '@/processors/notify'
export default {
  data () {
    return {
      diaryTransactions: {},
    }
  },
  methods: {
    fetchDiaryByDay (date) {
      this.$http({
        url: this.$store.state.url.diaryByDay,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), date: date },
      }).then((response) => {
        this.diaryTransactions = response.data
        console.log('--- fetched diary table by day: ')
        console.log(response.data)
        /* eslint-disable */
        $(document).ready(function () {
          $(document).foundation()
          // alert('rdy')
        })
      }, error => {
        errorHandle.httpError(error)
      })
    },
    fetchDiaryByYearByGuest (params) {
      this.$http({
        url: this.$store.state.url.diaryByYearByGuest,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), guestID: params.guestID, year: params.year },
      }).then((response) => {
        this.diaryTransactions = response.data
        // notify.success(response.body.msg)
        console.log('--- fetched diary table by year by guest: ')
        console.log(response.data)
        /* eslint-disable */
        /*
        $(document).ready(function () {
          $(document).foundation()
          // alert('rdy')
        })
        */
      }, error => {
        errorHandle.httpError(error)
      })
    },
  },
  components: {
    LeftMenu,
    DiaryTable,
  },
}
</script>

<style scoped>
section {
  height: 100vh;
  overflow: auto;
}
</style>
