<template>
  <div v-if="isLoaded"
       class="">
    <div class="row expanded collapse">
      <div class="medium-1 columns border-helper">
        <left-menu/>
      </div>
      <div class="medium-11 columns text-center border-helper"
           style="max-width:1000px;">
        <h3>Nyitvatartási szabályok</h3>
        <div class="row expanded">
          <div class="medium-12 columns tableBlock">
            <table class="hover">
              <thead>
              <tr>
                <th></th>
                <th>Típus</th>
                <th>Periódus</th>
                <th>Kezdő nap</th>
                <th>Utolsó nap</th>
                <th>Hét napja</th>
                <th>Mettől (óra)</th>
                <th>Meddig (óra)</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rule in ruleList">
                <td>
                  <a title="Szabály módosítása"
                     @click="openModal(rule)"><i class="fi-pencil"/></a>
                </td>
                <td>{{ rule.subject_type_name }}</td>
                <td>{{ rule.period_name }}</td>
                <td>{{ getFormattedDate(rule.valid_from) }}</td>
                <td>{{ getFormattedDate(rule.valid_to) }}</td>
                <td>
                  <span v-show="rule.weekday == 0">Hé</span>
                  <span v-show="rule.weekday == 1">Ke</span>
                  <span v-show="rule.weekday == 2">Sze</span>
                  <span v-show="rule.weekday == 3">Csü</span>
                  <span v-show="rule.weekday == 4">Pé</span>
                  <span v-show="rule.weekday == 5">Szo</span>
                  <span v-show="rule.weekday == 6">Vas</span>
                </td>
                <td>{{ rule.from_hour }}</td>
                <td>{{ rule.to_hour }}</td>
              </tr>
              </tbody>
            </table>
            <a @click="addNew()"><i class="fi-plus"/>&nbsp;Új szabály hozzáadása</a>
          </div>
        </div>
      </div>
    </div>
    <rule-modal @saved='fetchRuleList()'/>
  </div>
</template>


<script>
import LeftMenu from '../../left_menu/LeftMenu.vue';
import {getCookie} from '../../../processors/cookie';
import errorHandle from '@/processors/errorHandle';
import {getFormattedDate} from '../../../processors/util';
import RuleModal from './RuleModal.vue';

export default {
  components: {
    LeftMenu,
    RuleModal,
  },
  data () {
    return {
      ruleList: false,
    };
  },
  computed: {
    isLoaded () {
      return this.ruleList !== false;
    },
  }, //computed
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchRuleList();
  },
  methods: {
    fetchRuleList () {
      this.$http({
        url: this.$store.state.url.getRuleList,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token') },
      }).then(
        (response) => {
          this.ruleList = response.body;
        },
        (error) => {
          errorHandle.httpError(error);
        },
      );
    },
    getFormattedDate: getFormattedDate,
    openModal (rule) {
      this.$modal.show('RuleModal', { rule });
    },
    addNew () {
      this.$modal.show('RuleModal')
    },
  }, //methods
};
</script>
