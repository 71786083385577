<template>
  <div class="row expanded collapse">
    <div class="medium-1 columns border-helper">
      <left-menu/>
    </div>
    <div class="medium-11 columns text-center border-helper">
      <section>
        <!-- header and filter -->
        <div class="row expanded">
          <div class="medium-4 column">
            <div class="row expanded">
              <div class="medium-6">
                <label for="viewOptions">Nézet</label>
                <select
                  id="viewOptions"
                  v-model="selectedViewOption"
                >
                  <option
                    v-for="vOption in viewOptions"
                    :value="vOption.value"
                  >
                    {{ vOption.name }}
                  </option>
                </select>
              </div>
              <div class="medium-6">
                <label>Dátum szűrő</label>
                <DatePicker
                  v-model="dateFilter"
                  :format="selectedViewOption === 'year' ? 'yyyy' : 'yyyy MMMM'"
                  :minimum-view="selectedViewOption"
                  maximum-view="year"
                />
              </div>
            </div>
          </div>
          <div class="medium-4 column">
            <h3>Statisztikák</h3>
          </div>
          <div class="medium-4 column"/>
        </div>
        <!-- ./header and filter -->
        <div class="space-me"/>
        <!-- stats -->
        <div class="row expanded">
          <div class="small-12 medium-4 columns">
            <div class="callout primary">
              <h5>Foglalások</h5>
              <h5><strong>{{ reservationCount }}</strong></h5>
            </div>
          </div>
          <div class="small-12 medium-4 columns">
            <div class="callout success">
              <h5>Pénzmozgás</h5>
              <h5><strong>{{ getFormattedIncome(income) }}</strong></h5>
            </div>
          </div>
          <div class="small-12 medium-4 columns">
            <div class="callout warning">
              <h5>Lemondások</h5>
              <h5><strong>{{ resignCount }}</strong></h5>
            </div>
          </div>
        </div>
        <!-- ./stats -->
        <div class="space-me"/>
        {{ hasEnoughData(reservationDiagram) }}
        {{ hasEnoughData(incomeDiagram) }}
        {{ hasEnoughData(reservationHistoryDiagram) }}
        {{ hasEnoughData(buyDiagram) }}

        <!-- charts -->
        <div class="row expanded">
          <div class="small-12 medium-6 columns">
            <h5>Foglalások</h5>
            <GChart
              v-if="hasEnoughData(reservationDiagram)"
              :data="reservationDiagram"
              type="AreaChart"
            />
            <div
              v-else
              class="statistic-not-enough-data"
            >
              Nincs elég adat
            </div>
          </div>
          <div class="small-12 medium-6 columns">
            <h5>Bevétel</h5>
            <GChart
              v-if="hasEnoughData(incomeDiagram)"
              :data="incomeDiagram"
              type="LineChart"
            />
            <div
              v-else
              class="statistic-not-enough-data"
            >
              Nincs elég adat
            </div>
          </div>
          <div class="small-12 medium-6 columns">
            <h5>Pálya használati történet (foglalások, lemondások...)</h5>
            <GChart
              v-if="hasEnoughData(reservationHistoryDiagram)"
              :data="reservationHistoryDiagram"
              type="PieChart"
            />
            <div
              v-else
              class="statistic-not-enough-data"
            >
              Nincs elég adat
            </div>
          </div>
          <div class="small-12 medium-6 columns">
            <h5>Vásárlások</h5>
            <GChart
              v-if="hasEnoughData(buyDiagram)"
              :data="buyDiagram"
              type="ColumnChart"
            />
            <div
              v-else
              class="statistic-not-enough-data"
            >
              Nincs elég adat
            </div>
          </div>
        </div>
        <!-- ./charts -->
      </section>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../left_menu/LeftMenu.vue'
import {GChart} from 'vue-google-charts'
import DatePicker from 'vuejs-datepicker'
import {getFormattedDateTime, getNumberMoneyText} from '../../../processors/util'
import moment from 'moment'
// eslint-disable-next-line
import {getCookie} from '../../../processors/cookie'
// eslint-disable-next-line
import errorHandle from '@/processors/errorHandle'

export default {
  components: {
    LeftMenu,
    GChart,
    DatePicker,
  },
  data () {
    return {
      income: null,
      reservationCount: null,
      resignCount: null,
      reservationDiagram: null,
      incomeDiagram: null,
      reservationHistoryDiagram: null,
      buyDiagram: null,
      /* test data
        chartData: [
          ['Year', 'Sales', 'Expenses', 'Profit'],
          ['2014', 1000, 400, 200],
          ['2015', 1170, 460, 250],
          ['2016', 660, 1120, 300],
          ['2017', 1030, 540, 350]
        ],
        chartOptions: {
          chart: {
            title: 'Company Performance',
            subtitle: 'Sales, Expenses, and Profit: 2014-2017'
          }
        },
        */
      viewOptions: [
        { value: 'year', name: 'Év' },
        { value: 'month', name: 'Hónap' },
      ],
      selectedViewOption: 'year',
      dateFilter: getFormattedDateTime(),
    }
  },
  watch: {
    selectedViewOption: function (newViewOption, oldViewOption) {
      this.fetchStatistic()
    },
    dateFilter: function (newDate, oldDate) {
      this.fetchStatistic()
    },
  },
  created: function () {
    this.fetchStatistic()
  },
  methods: {
    fetchStatistic () {
      const selectedViewOption = this.selectedViewOption
      const selectedDate = getFormattedDateTime(this.dateFilter)
      const viewOptions = this.viewOptions
      const self = this

      if (moment(selectedDate).isValid() && viewOptions.some(option => option.value === selectedViewOption)) {
        this.$http({
          url: this.$store.state.url.getStatistic,
          method: 'GET',
          params: {
            userID: getCookie('userID'),
            token: getCookie('token'),
            selectedViewOption: selectedViewOption,
            selectedDate: selectedDate,
          },
        }).then((response) => {
          // this.diaryTransactions = response.data
          console.log('--- fetched statistic data: ')
          console.log(response.data)
          self.setNewStatisticData(response.data)
        }, error => {
          errorHandle.httpError(error)
        })
      }
    },
    setNewStatisticData (statisticData) {
      this.income = statisticData.income
      this.reservationCount = statisticData.reservationCount
      this.resignCount = statisticData.resignCount
      this.reservationDiagram = statisticData.reservationDiagram
      this.incomeDiagram = statisticData.incomeDiagram
      this.reservationHistoryDiagram = statisticData.reservationHistoryDiagram
      this.buyDiagram = statisticData.buyDiagram
    },
    hasEnoughData (elements) {
      if (elements && elements.hasOwnProperty('length') && elements.length > 1) {
        for (let i = 1; i < elements.length; i++) {
          const elem = elements[i]
          if (elem && elem.hasOwnProperty('length') && elem.length > 1) {
            for (let j = 1; j < elem.length; j++) {
              const subElem = elem[j]
              if (subElem > 0) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    getFormattedIncome: getNumberMoneyText,
  },
}
</script>

<style scoped>
section {
  max-height: 100vh;
  overflow: auto;
}

.callout {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
}

.space-me {
  height: 3rem;
}

.statistic-not-enough-data {
  margin: 2rem;
  font-size: 150%;
}
</style>
