<template>
  <div>
    <table class="unstriped box-border">
      <tbody>
      <tr>
        <td>Név:</td>
        <td>{{ currentGuest.name }}</td>
      </tr>
      <tr
        v-if="Number(currentGuest.balance) >= 0 && this.$store.state.guest.isSelected"
        class="positive-balance"
      >
        <td>Egyenleg:</td>
        <td>
          {{ currentGuest.balance }} Ft <i
          class="fi-list-thumbnails"
          title="Részletezés"
          @click="openCashFlowModal"
        />
        </td>
      </tr>
      <tr
        v-else-if="Number(currentGuest.balance) < 0"
        class="negative-balance flashit"
      >
        <td>Egyenleg (tartozás):</td>
        <td>
          {{ currentGuest.balance }} Ft <i
          class="fi-list-thumbnails"
          title="Részletezés"
          @click="openCashFlowModal"
        />
        </td>
      </tr>
      <tr v-else>
        <td>Egyenleg</td>
        <td>{{ currentGuest.balance }} Ft</td>
      </tr>
      <tr>
        <td>Típus:</td>
        <td>{{ getGuestTypeName(currentGuest.guestType) }}</td>
      </tr>
      </tbody>
      <cash-flow-modal/>
    </table>
  </div>
</template>

<script>
import UserAutoCompleteSearch from './UserAutoCompleteSearch.vue'
import CashFlowModal from './CashFlowModal.vue'
// import notify from '@/processors/notify'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UserAutoCompleteSearch,
    CashFlowModal,
  },
  computed: {
    currentGuest () {
      const guest = this.$store.state.guest.current

      const hasNotEmailPrefix = 'nincsEmailCime'
      if (guest.hasOwnProperty('email') && guest.email) {
        if (guest.email.includes(hasNotEmailPrefix)) {
          // this.$modal.show('changeEmailModal')
          // notify.info('A felhasználónak még nincs megadva email cím!')
          // alert('alert 1')
        }
      }
      return guest
    },
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
  },
  methods: {
    openCashFlowModal () {
      this.$store.dispatch('fetchCashFlow').then(() => {
        this.$modal.show('cashFlowModal')
      })
    },
    getGuestTypeName (guestTypeKey) {
      let guestTypeName = ''
      const guestTypes = this.$store.state.availableGuestTypes
      if (guestTypeKey) {
        for (let i = 0; i < guestTypes.length; i++) {
          const guestType = guestTypes[i]
          if (guestType.key === guestTypeKey) {
            guestTypeName = guestType.name
            break
          }
        }
      }
      return guestTypeName
    },
  },
}
</script>

<style scoped>
table {
  margin-bottom: 0;
  border-collapse: inherit;
}

tbody {
  border: none;
}

.flashit {
  /*color:#f2f;*/
  -webkit-animation: flash linear 1.5s infinite;
  animation: flash linear 1.5s infinite;
  font-weight: bold;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

</style>
