<template>
  <div class="maintenance">
    <h1>Hamarosan újra elérhetőek leszünk!</h1>
    <div>
      <p>{{ msg }}</p>
      <p>&mdash; A Foglaljpalyat.hu csapata</p>
    </div>
  </div>
</template>

<script>
import errorHandle from '@/processors/errorHandle'
import url from '@/api/url'

export default {
  computed: {
    msg () {
      return this.$route.query.msg || 'Karbantartás alatt'
    },
  },
  created: function () {
    this.$http({
      url: url.isUnderMaintenance,
      method: 'GET',
    }).then(response => {
      console.log('--- get under maintenance success')
      console.log(response)
      // eslint-disable-next-line
      if (response.body.hasOwnProperty('isUnderMaintenance')) {
        if (!response.body.isUnderMaintenance) {
          this.$router.push({ name: 'Reservation' })
        }
      }
    }, error => {
      errorHandle.httpError(error)
    })
  },
}
</script>

<style scoped>
.maintenance {
  text-align: center;
  padding: 150px;
  font: 20px Helvetica, sans-serif;
  color: #333;
}

.maintenance h1 {
  font-size: 50px;
}
</style>
