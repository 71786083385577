<template>
  <div
    id="userPassManageModal"
    class="scrollable-modal"
  >
    <modal
      height="500px"
      name="userPassManageModal"
      width="700px"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Felhasználó bérletei
        </h5>
        <table class="hover">
          <thead>
          <tr>
            <th>Bérlet</th>
            <th>Érvényes</th>
            <th>Alkalmak</th>
            <th>Szerkesztés</th>
          </tr>
          </thead>
          <tbody v-if="isSelectedGuest">
          <tr v-for="pass in currentGuestAllPasses">
            <td>{{ pass.name }}</td>
            <td>{{ formattedDate(pass.validUntil) }}</td>
            <td>{{ pass.currentOccasion }}/{{ pass.occasion }}</td>
            <td>
              <i
                class="fi-pencil"
                @click="editPass(pass.ID, pass.validUntil, pass.currentOccasion)"
              />
            </td>
          </tr>
          <tr v-show="currentGuestAllPasses.length <= 0">
            <td>Nincs bérlet/jegy</td>
          </tr>
          </tbody>
          <tbody v-else>
          Nincs vendég kiválasztva
          </tbody>
        </table>
      </div>
      <button
        aria-label="Close modal"
        class="close-button"
        data-close
        type="button"
        @click="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      passEditProps: {
        passPurchasedID: null,
        validUntil: null,
        currentOccasion: null,
      },
    }
  },
  computed: {
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
    currentGuestAllPasses () {
      if (this.$store.state.guest.current.hasOwnProperty('allPass')) {
        return this.$store.state.guest.current.allPass
      } else {
        return []
      }
    },
  },
  methods: {
    closeModal () {
      this.$modal.hide('userPassManageModal')
    },
    formattedDate (date) {
      return moment(date).format('YYYY.MM.DD')
    },
    editPass (passPurchasedID, validUntil, currentOccasion) {
      const data = {
        passPurchasedID: passPurchasedID,
        validUntil: moment(validUntil).format('YYYY-MM-DD'),
        currentOccasion: currentOccasion,
      }

      this.$modal.show('userPassEditModal', data)
    },
  },
}
</script>
