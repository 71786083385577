<template>
  <div
    v-if="isLoaded"
    class=""
  >
    <div class="row expanded collapse">
      <div class="medium-1 columns border-helper">
        <left-menu/>
      </div>
      <div class="medium-3 columns text-center border-helper">
        <section>
          <div class="row expanded">
            <div class="medium-12 columns">
              <span>Egy nap lezárása:</span>
              <div class="switch">
                <input
                  id="exampleSwitch"
                  v-model="isSingleDay"
                  class="switch-input"
                  name="exampleSwitch"
                  type="checkbox"
                >
                <label
                  class="switch-paddle"
                  for="exampleSwitch"
                >
                  <span class="show-for-sr">isSingleDay</span>
                </label>
              </div>
            </div>
          </div>
          <new-close-single-day
            v-if="isSingleDay"
            @fetchCloseDataByYear="fetchCloseDataByYear"
          />
          <new-close-multiple-day
            v-else
            @fetchCloseDataByYear="fetchCloseDataByYear"
          />
        </section>
      </div>
      <div class="medium-8 columns">
        <section>
          <close-table
            :close-data="closeData"
            @fetchCloseDataByYear="fetchCloseDataByYear"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../left_menu/LeftMenu.vue'
import NewCloseMultipleDay from './components/NewCloseMultipleDay.vue'
import NewCloseSingleDay from './components/NewCloseSingleDay.vue'
import CloseTable from './components/CloseTable.vue'
import {getCookie} from '../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'

export default {
  components: {
    NewCloseMultipleDay,
    LeftMenu,
    NewCloseSingleDay,
    CloseTable,
  },
  data () {
    return {
      isSingleDay: true,
      closeData: [],
    }
  },
  computed: {
    isLoaded () {
      let isLoaded = false
      if (this.$store.state.hasOwnProperty('subjectTypes')) {
        isLoaded = true
      }
      return isLoaded
    },
  },
  methods: {
    fetchCloseDataByYear () {
      this.$http({
        url: this.$store.state.url.getClose,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token') },
      }).then(response => {
        this.closeData = response.body
        console.log('***********************************')
        console.log('--- close data fetched: ')
        console.log(response.body)
        console.log('***********************************')
      }, error => {
        errorHandle.httpError(error)
      })
    },
  },
}
</script>

<style scoped>
.switch {
  display: inline-block;
}
</style>
