<template>
  <div id="userActions">
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Felhasználó keresés"
      @click="openUserSelectModal()"
    >
      <i class="fi-magnifying-glass"/>
    </button>
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Vásárlás"
      @click="openPurchaseModal()"
    >
      <i
        :class="availableClass"
        class="fi-shopping-cart"
      />
    </button>
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Bérlet vásárlás"
      @click="openPassPurchaseModal()"
    >
      <i
        :class="availableClass"
        class="fi-ticket"
      />
    </button>
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Profil"
      @click="openUserEditModal()"
    >
      <i
        :class="availableClass"
        class="fi-torso"
      />
    </button>
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Új vendég felvétele"
      @click="openNewUserModal()"
    >
      <i class="fi-plus"/>
    </button>
    <button
      aria-haspopup="true"
      data-tooltip
      tabindex="1"
      title="Kiválasztás elvetése"
      @click="unselectGuest()"
    >
      <i
        :class="availableClass"
        class="fi-refresh"
      />
    </button>

    <user-select-modal/>
    <user-edit-modal/>
    <user-purchase-modal/>
    <new-user-modal/>
  </div>
</template>

<script>
import UserSelectModal from './components/UserSelectModal.vue'
import UserEditModal from './components/UserEditModal.vue'
import UserPurchaseModal from './components/UserPurchaseModal.vue'
import NewUserModal from './components/NewUserModal.vue'

export default {
  components: {
    UserSelectModal,
    UserEditModal,
    UserPurchaseModal,
    NewUserModal,
  },
  computed: {
    availableClass () {
      return {
        deniedAction: !this.$store.state.guest.isSelected,
      }
    },
  },
  created: function () {
  },
  methods: {
    unselectGuest () {
      this.$store.dispatch('unselectGuest')
      this.$store.state.table.selectedTimeTableID = null
    },
    openUserSelectModal () {
      this.$modal.show('userSelectModal')
    },
    openUserEditModal () {
      if (this.$store.state.guest.isSelected) {
        this.$modal.show('userEditModal')
      }
    },
    openPurchaseModal () {
      if (this.$store.state.guest.isSelected) {
        this.$modal.show('userPurchaseModal', { isTicket: false, isPassBuy: false, isOtherItemBuy: true })
      }
    },
    openPassPurchaseModal () {
      if (this.$store.state.guest.isSelected) {
        this.$modal.show('userPurchaseModal', { isTicket: false, isPassBuy: true, isOtherItemBuy: false })
      }
    },
    openNewUserModal () {
      this.$modal.show('newUserModal')
    },
  },
}
</script>

<style scoped>
i {
  font-size: 200%;
  margin: 10px;
  cursor: pointer;
  color: #3b3b3b;
}

#userActions {
  margin-bottom: 10px;
}

.deniedAction {
  cursor: not-allowed;
}
</style>
