<template>
	<div class="scrollable-modal">
		<modal height="470px" name="newUserModal" @closed="modalClosed">
			<div class="modal-content">
				<h5 class="text-center bottom-border-separator">
					Felhasználó létrehozása
				</h5>
				<div class="row">
					<div class="medium-12 columns">
						<label>
							Név
							<input v-model="newUserName" :class="{ 'is-invalid-input': showError.name }" type="text"
								@blur="blurName">
							<span v-if="showError.name" class="form-error is-visible">A név kitöltése kötelező</span>
						</label>
					</div>
					<div class="medium-12 columns">
						<label>
							Email
							<input v-model="newUserEmail" :class="{ 'is-invalid-input': showError.email }"
								name="newUserEmail" type="text" @blur="blurEmail">
							<span v-if="showError.email" class="form-error is-visible">
								Email kitöltése kötelező, helyes formátum: minta@mail.hu
							</span>
						</label>
					</div>
					<div class="medium-12 columns">
						<label>
							Telefonszám
							<vue-tel-input v-model="newUserPhone" :class="{ 'is-invalid-input': showError.phone }"
								name="newUserPhone" default-country="HU" @onBlur="blurPhone"
								@onValidate="onPhoneValidate" />
							<span v-if="showError.phone" class="form-error is-visible">
								Csak érvényes telefonszám adható meg
							</span>
						</label>
					</div>
					<div class="medium-12 columns">
						<label>
							Felhasználó típus
							<select v-model="newUserGuestType">
								<option v-for="guestType in guestTypes" :key="guestType.key" :value="guestType.key">{{
									guestType.name }}
								</option>
							</select>
						</label>
					</div>
					<div class="medium-12 columns text-right top-border-separator">
						<button class="button" @click="createNewUser">
							Felhasználó létrehozása
						</button>
						<button class="button secondary" @click="closeModal">
							Mégse
						</button>
					</div>
				</div>
			</div>
			<button aria-label="Close modal" class="close-button" data-close type="button" @click="closeModal()">
				<span aria-hidden="true">&times;</span>
			</button>
		</modal>
	</div>
</template>

<script>
import { getCookie } from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueTelInput from 'vue-tel-input'

export default {
	components: {
		VueTelInput
	},
	data() {
		return {
			newUserName: null,
			newUserEmail: null,
			newUserPhone: null,
			newUserGuestType: 'guest',
			isNameTouched: false,
			isEmailTouched: false,
			isPhoneTouched: false,
		}
	},
	computed: {
		guestTypes() {
			return this.$store.state.availableGuestTypes
		},
		isNameValid() {
			let isValid = false
			if (this.newUserName) {
				isValid = this.newUserName.length > 0
			}
			return isValid
		},
		isEmailValid() {
			const email = this.newUserEmail
			// eslint-disable-next-line
			let regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return regExp.test(email)
		},
		showError() {
			return {
				name: this.isNameTouched ? !this.isNameValid : false,
				email: this.isEmailTouched ? !this.isEmailValid : false,
				phone: !!this.newUserPhone && !this.isPhoneValid,
			}
		},
	},
	methods: {
		modalClosed() {
			this.newUserName = null
			this.newUserEmail = null
			this.newUserGuestType = 'guest'
			this.newUserPhone = null
			this.isNameTouched = false
			this.isEmailTouched = false
		},
		createNewUser() {
			const newUserData = {
				name: this.newUserName,
				email: this.newUserEmail,
				phone: this.newUserPhone,
				guestType: this.newUserGuestType,
			}
			console.log('--- new user data: ')
			console.log(newUserData)
			if (this.isEmailValid && this.isNameValid) {
				this.$http({
					url: this.$store.state.url.newGuest,
					method: 'POST',
					params: { userID: getCookie('userID'), token: getCookie('token') },
					body: JSON.stringify({ data: newUserData }),
				}).then((response) => {
					notify.success(response.body.msg)
					console.log('--- new user added')
				}, error => {
					errorHandle.httpError(error)
				}).finally(() => {
					this.$modal.hide('newUserModal')
				})
			} else {
				notify.warning('Név és email-cím kitöltése kötelező!')
			}
		},
		closeModal() {
			this.$modal.hide('newUserModal')
		},
		blurName() {
			this.isNameTouched = true
		},
		blurEmail() {
			this.isEmailTouched = true
		},
		blurPhone() {
			this.isPhoneTouched = true
		},
		onPhoneValidate({ number, isValid, country }) {
			// alert('validate')
			this.isPhoneValid = isValid
		},

	},
}
</script>

