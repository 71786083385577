<template>
  <div>
    <div class="row">
      <div class="medium-6 columns">
        <label for="selectParticipants"/>
        <select
          id="selectParticipants"
          multiple
        >
          <option
            v-for="user in usersWithoutParticipants"
            :guest-name="user.name"
            :value="user.ID"
          >
            {{ user.name }}
          </option>
        </select>
      </div>
      <div class="medium-6 columns">
        <label for="currentParticipants"/>
        <select
          id="currentParticipants"
          multiple
        >
          <option
            v-for="participant in currentParticipants"
            :guest-name="participant.guestName"
            :value="participant.guestID"
          >
            {{ participant.guestName }}
          </option>
        </select>
      </div>
      <div class="medium-6 columns">
        <button
          class="button"
          @click="addParticipants()"
        >
          Részvevők hozzáadása
        </button>
      </div>
      <div class="medium-6 columns">
        <button
          class="button secondary"
          @click="removeParticipants()"
        >
          Résztvevők eltávolítása
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['participants'],
  data () {
    return {
      curParticipants: this.participants,
    }
  },
  computed: {
    usersWithoutParticipants () {
      const filteredParticipants = this.$store.state.guest.all.slice(0)
      const resOwnerGuestID = Number(this.$store.state.guest.current.ID)

      for (let i = filteredParticipants.length - 1; i >= 0; i--) {
        for (let j = 0; j < this.curParticipants.length; j++) {
          // remove the current participants from the list
          if (filteredParticipants[i].ID && this.curParticipants[j].guestID) {
            if (Number(filteredParticipants[i].ID) === Number(this.curParticipants[j].guestID)) {
              filteredParticipants.splice(i, 1)
              break
            }
          }
        }
        // remove the reservation owner to the list
        if (Number(filteredParticipants[i].ID === resOwnerGuestID)) {
          filteredParticipants.splice(i, 1)
        }
      }

      return filteredParticipants
    },
    currentParticipants () {
      return this.curParticipants
    },
  },
  methods: {
    testData () {
      console.log(this.participants)
    },
    addParticipants () {
      const selectElem = document.getElementById('selectParticipants')
      const options = selectElem && selectElem.options
      let opt

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        if (opt.selected) {
          this.curParticipants.push({ guestID: opt.value, guestName: opt.getAttribute('guest-name') })
        }
      }
    },
    removeParticipants () {
      const currentParticipantsElem = document.getElementById('currentParticipants')
      const options = currentParticipantsElem && currentParticipantsElem.options
      let opt
      const curParticipants = this.curParticipants.slice(0)

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        if (opt.selected) {
          for (let j = curParticipants.length - 1; j >= 0; j--) {
            if (Number(curParticipants[j].guestID) === Number(opt.value)) {
              curParticipants.splice(j, 1)
            }
          }
        }
      }
      this.curParticipants = curParticipants
    },
  },
}
</script>
