<template>
  <div class="auto-complete-box">
    <auto-complete
      :source="allGuest"
      placeholder="Felhasználó keresés"
      @selected="selectGuest"
    />
  </div>
</template>

<script>
import AutoComplete from 'vuejs-auto-complete'

export default {
  components: {
    AutoComplete,
  },
  computed: {
    allGuest () {
      return this.$store.state.guest.all
    },
  },
  methods: {
    selectGuest (resp) {
      const guest = resp.selectedObject
      console.log('$$$')
      console.log(guest)
      this.$store.dispatch('fetchGuestByID', { reqUserID: guest.ID }).then(() => {
        this.$store.state.table.selectedTimeTableID = null
        // TODO: Hibakezelés, ha nem kapta meg a startup adatot
        // console.log('This would be printed after dispatch!!')
        console.log('--- Guest is selected: ' + guest.ID)
        this.$emit('user-selected')
      })
    },
  },
}
</script>

<style scoped>
.auto-complete-box {
  margin-bottom: 0.6rem;
}
</style>
