<template>
  <div class="dataTable">
    <table class="hover">
      <thead>
      <tr>
        <th>Bérlet</th>
        <th>
          Alkalmak
          <span class="float-right pointer-cursor">
              <i
                class="fi-clipboard-pencil"
                title="Részletezés"
                @click="openAllPassModal()"
              />
            </span>
        </th>
      </tr>
      </thead>
      <tbody v-if="isSelectedGuest">
      <tr
        v-for="pass in currentGuestPasses"
        :class="{'not-valid-pass': !isValidPass(pass)}"
        @click="selectPass(pass)"
      >
        <td>
          <span v-if="!isValidPass(pass)">Lejárt!</span>
          {{ pass.name }}
        </td>
        <td v-if="!isSeasonPass(pass)">
          {{ pass.currentOccasion }}/{{ pass.occasion }}
        </td>
        <td v-else>
          -
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      Nincs vendég kiválasztva
      </tbody>
    </table>
    <div v-show="currentGuestPasses.length <= 0">
      A felhasználónak nincsen bérlete/jegye
    </div>
    <user-pass-manage-modal/>
    <user-pass-edit-modal/>
  </div>
</template>

<script>
import UserPassEditModal from './UserPassEditModal.vue'
import UserPassManageModal from './UserPassManageModal.vue'
import moment from 'moment'

export default {
  components: {
    UserPassEditModal,
    UserPassManageModal,
  },
  computed: {
    currentGuestPasses () {
      return this.$store.state.guest.current.passes
    },
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
  },
  methods: {
    openAllPassModal () {
      this.$store.dispatch('fetchGuestAllPass', { reqUserID: this.$store.state.guest.current.ID })
      this.$modal.show('userPassManageModal')
    },
    selectPass (pass) {
      const data = {
        passPurchasedID: pass.ID,
        validUntil: moment(pass.validUntil).format('YYYY-MM-DD'),
        currentOccasion: pass.currentOccasion,
      }

      this.$modal.show('userPassEditModal', data)
    },
    isSeasonPass (pass) {
      return !!(pass && pass.hasOwnProperty('occasion') && pass.hasOwnProperty('currentOccasion') &&
        Number(pass.occasion) === 0 && Number(pass.currentOccasion) === 0)
    },
    isValidPass (pass) {
      let isValid = false
      if (pass.hasOwnProperty('validUntil')) {
        if (moment(pass.validUntil).isSameOrAfter(moment())) {
          isValid = true
        }
      }
      return isValid
    },
  },
}
</script>

<style scoped>
tr {
  cursor: pointer;
}

.not-valid-pass {
  background: #ffb2b2;
}

.not-valid-pass:hover {
  background: #ffa7a2;
}
</style>
