<template>
  <div>
    <div class="table-filter-block">
      <div class="row">
        <div class="small-6 columns">
          <label>
            <input
              v-model="filterSearch"
              placeholder="Keresés"
              type="text"
            >
          </label>
        </div>
        <div class="small-6 columns">
          <label>
            <select v-model="filterActive">
              <option value="">Összes</option>
              <option value="1">Aktív</option>
              <option value="0">Inaktív</option>
            </select>
          </label>
        </div>
      </div>
    </div>
    <table>
      <thead>
      <tr>
        <th>Név</th>
        <th>Email</th>
        <th>Tel</th>
        <th>Aktív</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="guest in filteredGuests"
        @click="selectGuest(guest.ID)"
      >
        <td>{{ guest.name }}</td>
        <td>{{ guest.email }}</td>
        <td>{{ guest.phone }}</td>
        <td>
          <span v-if="guest.isActive">Aktív</span>
          <span v-else>Inaktív</span>
        </td>
      </tr>
      </tbody>
    </table>
    <ul
      aria-label="Pagination"
      class="pagination text-center"
      role="navigation"
    >
      <li
        :class="prevClassObject"
        class="pagination-previous"
      >
        <a
          v-if="!prevClassObject.disabled"
          aria-label="Prev page"
          @click="prevPage()"
        >Előző</a>
        <span v-else>Előző</span>
      </li>
      <li
        :class="nextClassObject"
        class="pagination-next"
      >
        <a
          v-if="!nextClassObject.disabled"
          aria-label="Next page"
          @click="nextPage()"
        >Következő</a>
        <span v-else>Következő</span>
      </li>
      <li>{{ currentPaginationPage }}/{{ paginationMaxPage }}</li>
      <li>Összesen: {{ guestNumber }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filterSearch: '',
      currentPaginationPage: 1,
      paginationMaxPage: 1,
      paginationLimit: 25,
      filterActive: '',
      guestNumber: 0,
    }
  },
  computed: {
    filteredGuests () {
      let oldGuests = this.$store.state.guest.all
      let filteredGuests = []
      // global filter
      if (this.filterSearch.length) {
        oldGuests = this.globalFilter(oldGuests)
      }
      // active/passive filters
      /* eslint-disable */
      if (this.filterActive !== '') {
        oldGuests = this.activeFilter(oldGuests)
      }

      // pagination
      this.paginationMaxPage = Math.ceil(oldGuests.length / this.paginationLimit)
      this.guestNumber = oldGuests.length
      let startIndex = (this.currentPaginationPage - 1) * this.paginationLimit
      let endIndex = this.currentPaginationPage * this.paginationLimit
      filteredGuests = oldGuests.slice(startIndex, endIndex)
      return filteredGuests
    },
    prevClassObject () {
      return {
        disabled: this.currentPaginationPage === 1,
      }
    },
    nextClassObject () {
      return {
        disabled: this.currentPaginationPage === this.paginationMaxPage,
      }
    },
  },
  methods: {
    globalFilter (oldGuests) {
      const filteredGuests = []
      for (let i = 0; i < oldGuests.length; i++) {
        if (Object.values(oldGuests[i]).toString().toLowerCase().includes(this.filterSearch.toLowerCase())) {
          filteredGuests.push(oldGuests[i])
        }
      }
      return filteredGuests
    },
    activeFilter (oldGuests) {
      const filteredGuests = []
      for (let i = 0; i < oldGuests.length; i++) {
        if (oldGuests[i].isActive.toString() === this.filterActive.toString()) {
          filteredGuests.push(oldGuests[i])
        }
      }
      return filteredGuests
    },
    prevPage () {
      if (this.currentPaginationPage > 1) {
        this.currentPaginationPage--
      }
    },
    nextPage () {
      if (this.currentPaginationPage < this.paginationMaxPage) {
        this.currentPaginationPage++
      }
    },
    selectGuest (userID) {
      this.$store.dispatch('fetchGuestByID', { reqUserID: userID }).then(() => {
        this.$store.state.table.selectedTimeTableID = null
        // TODO: Hibakezelés, ha nem kapta meg a startup adatot
        // console.log('This would be printed after dispatch!!')
        console.log('--- Guest is selected: ' + userID)
        this.$modal.hide('userSelectModal')
      })
    },
  },
}
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
