<template>
  <div>
    <div
      class="logOutBtn"
      @click="logOut"
    >
      <i class="fi-power"/>
    </div>
  </div>
</template>

<script>
import {logOut} from '../../../processors/auth'
// import auth, { logOut } from '../../../processors/auth'
import {getCookie} from '../../../processors/cookie'
import url from '../../../api/url'

export default {
  data () {
    return {}
  },
  methods: {
    logOut () {
      // this.$http.delete(url.logOut, {params: {userID: getCookie('userID'), token: getCookie('token')}})
      this.$http(
        {
          url: url.logOut,
          method: 'DELETE',
          params: { userID: getCookie('userID'), token: getCookie('token') },
        },
      ).then(function (resp) {
        logOut()
      }, function (resp) {
        logOut()
        alert('SERVER LOGOUT ERROR')
      })
    },
  },
}
</script>

<style scoped>
.logOutBtn {
  width: 100%;
  /*font-size: 300%;*/
  cursor: pointer;
  color: #3b3b3b;
}
</style>
