<template>
  <div class="dataTable">
    <table class="hover">
      <thead>
      <tr>
        <th>Pálya</th>
        <th>
          Mikor
          <span class="float-right pointer-cursor">
              <i
                class="fi-clipboard-pencil"
                title="Részletezés"
                @click="openAllReservationModal()"
              />
            </span>
        </th>
      </tr>
      </thead>
      <tbody v-if="isSelectedGuest">
      <tr
        v-for="res in currentGuestActiveReservations"
        class="res-row no-select"
        @click="selectReservation(res.timeTableID, res.subject.typeID, res.date)"
      >
        <td>{{ getFormattedSubject(res.subject.typeName, res.subject.name) }}</td>
        <td>{{ getFormattedDate(res.date) }}: {{ getFormattedTime(res.time.start) }} - {{
            getFormattedTime(res.time.end)
          }}
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      Nincs vendég kiválasztva
      </tbody>
    </table>
    <div v-show="currentGuestActiveReservations.length <= 0">
      A felhasználónak nincsen aktív foglalása
    </div>
    <user-reservation-manage-modal/>
  </div>
</template>

<script>
import moment from 'moment'
import UserReservationManageModal from './UserReservationManageModal.vue'
import {getFormattedTime} from '../../../../processors/util'

export default {
  components: {
    UserReservationManageModal,
  },
  computed: {
    currentGuestActiveReservations () {
      return this.$store.state.guest.current.activeReservations
    },
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
  },
  methods: {
    selectReservation (timeTableID, subjectTypeID, reservationDate) {
      const currentTableSubjectType = this.$store.state.table.currentSubjectTypeID
      const currentTableDate = this.$store.state.table.currentTableDate

      // set current table selectedTimeTableID
      this.$set(this.$store.state.table, 'selectedTimeTableID', timeTableID)

      // refetch table if the date is difference
      if (!moment(currentTableDate).isSame(reservationDate, 'day')) {
        this.$store.dispatch('fetchTableByDate', { date: reservationDate })
      }

      // set table subject type id
      if (Number(subjectTypeID) !== Number(currentTableSubjectType)) {
        this.$set(this.$store.state.table, 'currentSubjectTypeID', subjectTypeID)
      }
    },
    getFormattedDate (date) {
      return moment(date).format('MM.DD')
    },
    getFormattedTime: getFormattedTime,
    getFormattedSubject (subjectType, subject) {
      return subjectType + '/' + subject
    },
    openAllReservationModal () {
      this.$store.dispatch('fetchGuestAllReservation', { reqUserID: this.$store.state.guest.current.ID })
      this.$modal.show('userReservationManageModal')
    },
  },
}
</script>

<style scoped>
.res-row {
  cursor: pointer;
}
</style>
