<template>
  <modal
    height="600px"
    name="userGlobalCommentModal"
    width="800px"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <!-- comments -->
      <div class="comment-section-container">
        <h3>Megjegyzések ({{ comments.length }})</h3>
        <div v-for="comment in comments">
          <div class="comment-section-author">
            <img
              :src="comment.clientLogo"
              alt=""
            >
            <div class="comment-section-name">
              <h5>
                <span>{{ comment.clientName }}
                  <a
                    v-if="isCurrentClient(comment.clientDomain)"
                    class="cancel-comment-button"
                    @click="cancelComment(comment.msgID)"
                  >Visszavonás</a>
                </span>
              </h5>
              <p>{{ getDateText(comment.createdAt) }}</p>
            </div>
          </div>
          <div class="comment-section-text">
            <p>
              {{ comment.comment }}
            </p>
          </div>
        </div>
      </div>
      <!--/comments-->

      <!-- comment form -->
      <form class="comment-section-form">
        <div class="comment-section-box">
          <div class="row">
            <div class="small-12 column">
              <h4>Globális megjegyzés</h4>
              <label>Megjegyzés
                <textarea
                  v-model="currentComment"
                  rows="10"
                  type="text"
                />
              </label>
              <button
                class="button expanded"
                @click="createNewComment"
              >
                Megjegyzés mentése
              </button>
            </div>
          </div>
        </div>
      </form>
      <!--/comment box-->
    </div>
    <button
      aria-label="Close modal"
      class="close-button"
      data-close
      type="button"
      @click="closeGlobalCommentModal"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </modal>
</template>

<script>
import {getCookie} from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import moment from 'moment'

export default {
  data () {
    return {
      comments: [],
      currentComment: '',
    }
  },
  created: function () {
    // this.fetchGlobalComments()
  },
  methods: {
    beforeOpen () {
      this.fetchGlobalComments()
    },
    getDateText (date) {
      return moment(date).format('YYYY.MM.DD HH:mm')
    },
    isCurrentClient (domain) {
      return domain === window.location.host
    },
    fetchGlobalComments () {
      this.$http({
        url: this.$store.state.url.globalComments,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), email: this.$store.state.guest.current.email },
      }).then((response) => {
        console.log('***********************************')
        console.log('--- Fetched user global comments success')
        console.log(response.body)
        console.log('***********************************')
        this.comments = response.body.comments
        // this.$store.dispatch('fetchTableByDate', response.body)
      }).catch(error => {
        errorHandle.httpError(error)
      })
    },
    createNewComment () {
      this.$http({
        url: this.$store.state.url.globalComments,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: { email: this.$store.state.guest.current.email, comment: this.currentComment },
      }).then((response) => {
        console.log('***********************************')
        console.log('--- Create new user global comments success')
        notify.success(response.body.msg)
        this.currentComment = ''
        this.fetchGlobalComments()
      }).catch(error => {
        errorHandle.httpError(error)
      })
    },
    cancelComment (msgID) {
      this.$http({
        url: this.$store.state.url.globalComments,
        method: 'DELETE',
        params: { userID: getCookie('userID'), token: getCookie('token'), msgID: msgID },
      }).then((response) => {
        console.log('***********************************')
        console.log('--- Cancel user global comments success')
        notify.success(response.body.msg)
        this.fetchGlobalComments()
      }).catch(error => {
        errorHandle.httpError(error)
      })
    },
    closeGlobalCommentModal () {
      this.$modal.hide('userGlobalCommentModal')
    },
  },
}
</script>

<style scoped>
.comment-section-container {
  background-color: #fefefe;
  padding: 1rem;
}

.comment-section-author {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}

.comment-section-author .comment-section-name {
  margin-left: 1rem;
}

.comment-section-author .comment-section-name p {
  margin-bottom: 0;
}

img {
  width: 4rem;
}

.cancel-comment-button {
  font-size: 70%;
}
</style>
