<template>
  <div
    id="cashFlowModal"
    class="scrollable-modal"
  >
    <modal
      height="600px"
      name="cashFlowModal"
      width="1000px"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Játékos pénzmozgás
        </h5>
        <div class="row">
          <div class="medium-12 columns">
            <table
              v-if="cashFlow.length > 0"
              class="hover"
            >
              <thead>
              <tr>
                <th>Admin</th>
                <th>Felh.</th>
                <th>Traz. típus</th>
                <th>Megnev.</th>
                <th>DB/Alk.</th>
                <th>Ár</th>
                <th>Fizetés</th>
                <th>Létrehozva</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in cashFlow">
                <td>{{ row.adminName }}</td>
                <td>{{ row.userName }}</td>
                <td>{{ getTransactionTypeName(row.transactionType) }}</td>
                <td>{{ getFormattedText(row.itemName) }}</td>
                <td>{{ getFormattedText(row.quantity) }}</td>
                <td>{{ getFormattedText(row.price, 'price') }}</td>
                <td>{{ getFormattedText(row.payment, 'payment') }}</td>
                <td>{{ getFormattedDateTime(row.createdAt) }}</td>
              </tr>
              </tbody>
            </table>
            <div
              v-else=""
              class="callout warning text-center"
            >
              A felhasználónak még nem volt pénzmozgása
            </div>
          </div>
        </div>
      </div>
      <button
        aria-label="Close modal"
        class="close-button"
        data-close
        type="button"
        @click="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import {getFormattedDateTime} from '../../../../processors/util'

export default {
  computed: {
    cashFlow () {
      return this.$store.state.guest.cashFlow
    },
  },
  methods: {
    getTransactionTypeName (transactionKey) {
      const transactionTypes = this.$store.state.transactionTypes
      let transactionName = ''
      for (let i = 0; i < transactionTypes.length; i++) {
        if (transactionTypes[i].hasOwnProperty('key') && transactionTypes[i].hasOwnProperty('name')) {
          if (transactionTypes[i].key === transactionKey) {
            transactionName = transactionTypes[i].name
            break
          }
        }
      }
      return transactionName
    },
    getFormattedText (text, col) {
      // if null or not exist show '-'
      let formattedText = '-'
      if (text) {
        formattedText = '' + text
        if (col === 'payment' || col === 'price') {
          formattedText += ' Ft'
        }
      }
      return formattedText
    },
    getFormattedDateTime: getFormattedDateTime,
    closeModal () {
      this.$modal.hide('cashFlowModal')
    },
  },
}
</script>

<style scoped>
.callout {
  padding: 1rem;
}
</style>
