<template>
  <div class="row expanded collapse">
    <div class="medium-1 columns border-helper">
      <left-menu/>
    </div>
    <div class="medium-11 columns border-helper"/>
  </div>
</template>

<script>
import LeftMenu from './left_menu/LeftMenu.vue'

export default {
  components: {
    LeftMenu,
  },
  data () {
    return {}
  },
}
</script>

<style scoped>
.border-helper {
  border-right: 1px solid black;
}
</style>
