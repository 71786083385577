<template>
  <div id="timetable">
    <div
      id="settingPanel"
      class="row small-up-3"
    >
      <div class="column">
        <label>
          <input
            :value="currentTableDate"
            type="date"
            @change="updateCurrentTableDate($event.target.value)"
          >
        </label>
      </div>
      <div class="column text-center">
        <h5 class="no-select">
          <i
            class="fi-arrow-left"
            title="Előző nap"
            @click="decreaseCurrentDay"
          />
          {{ getNameOfCurrentDay() }}
          <i
            class="fi-arrow-right"
            title="Következő nap"
            @click="increaseCurrentDay"
          />
        </h5>
      </div>
      <div class="column">
        <label>
          <select
            :value="currentSubjectTypeID"
            @change="selectSubjectType($event.target.value)"
          >
            <option
              v-for="subjectType in $store.state.subjectTypes"
              :value="subjectType.ID"
            >
              {{ subjectType.name }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="row collapse box-border">
      <div class="small-1 columns">
        <div class="row">
          <div class="timetable-header-block text-center">
            idő
          </div>
          <div
            :style="styleTableContent"
            class="text-center"
          >
            <div
              v-for="hour in hourArray"
              :style="stylePeriod(hour)"
            >
              <div>
                {{ hour }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="small-11 columns">
        <div
          :class="classTableColNumber"
          class="row collapse"
        >
          <div
            v-for="subject in currentTable.subjects"
            class="column"
          >
            <div class="timetable-header-block text-center">
              {{ subject.name }}
            </div>
            <div class="subject-col">
              <div
                v-for="timeBlock in subject.fill"
                :class="{'selected-timeblock': isThisSelected(timeBlock.timeTableID),
                         'deniedAction': isThisDeniedAction(timeBlock.status)}"
                :style="getTimeBlockStyle(timeBlock.time, timeBlock.status)"
                class="timeBlock"
                @click="timeBlockClick(timeBlock, subject.ID)"
              >
                <div class="row collapse text-center">
                  <div class="medium-3 columns">
                    <!-- ticket icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isTicket(timeBlock)"
                        class="fi-ticket"
                        title="Jegy"
                      />
                    </span>
                    <!-- ./ticket icon -->
                    <!-- pass icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isPass(timeBlock)"
                        class="fi-book"
                        title="Bérlet"
                      /></span>
                    <!-- ./pass icon -->
                    <!-- permanent reservation icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isPermanent(timeBlock) &&
                          !timeBlockState().isPass(timeBlock) &&
                          !timeBlockState().isTicket(timeBlock)"
                        class="fi-anchor"
                        title="Állandó foglalás"
                      />
                    </span>
                    <!-- ./permanent reservation icon -->
                    <!-- <span class="statusIcon"><i v-show="timeBlockState().isReserved(timeBlock)" class="fi-target"></i></span> -->
                    <!-- <span class="statusIcon"><i v-show="timeBlockState().isFree(timeBlock)" class="fi-skull"></i></span> -->
                    <!-- closed icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isClosed(timeBlock)"
                        class="fi-lock"
                        title="Lezárt alkalom"
                      /></span>
                    <!-- ./closed icon -->
                  </div>
                  <div class="medium-6 columns">
                    <!--<span class="">{{ getFormattedTime(timeBlock.time.start, timeBlock.time.end) }}</span>-->
                    <span
                      v-if="getReservationName(timeBlock) !== false"
                      class=""
                    >
                      {{ getReservationName(timeBlock) }}
                    </span>
                  </div>
                  <div class="medium-3 columns">
                    <!-- trainer icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isTrainer(timeBlock)"
                        class="fi-sheriff-badge"
                        title="Edző/Oktató"
                      /></span>
                    <!-- ./trainer icon -->
                    <!-- <i class="fi-trophy"></i> -->
                    <!-- guest icon -->
                    <span class="statusIcon">
                      <i
                        v-show="timeBlockState().isGuest(timeBlock)"
                        class="fi-torso"
                        title="Vendég"
                      /></span>
                    <!-- ./guest icon -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <schedule-icon-legend/>
    <new-reservation-modal/>
    <edit-reservation-modal/>
    <attended-modal/>
  </div>
</template>

<script>
import NewReservationModal from './components/NewReservationModal.vue'
import EditReservationModal from './components/EditReservationModal.vue'
import AttendedModal from './components/AttendedModal.vue'
import ScheduleIconLegend from './components/ScheduleIconLegend.vue'
import moment from 'moment'
// import {getCookie} from '../../../../../processors/cookie'
export default {
  components: {
    NewReservationModal,
    EditReservationModal,
    AttendedModal,
    ScheduleIconLegend,
  },
  data () {
    return {
      tableContentHeight: 80,
      curTableData: {
        firstHour: 1,
        lastHour: 1,
      },
      historyTable: [],
    }
  },
  computed: {
    isThisDeniedAction () {
      return function (status) {
        const isUserSelected = this.$store.state.guest.isSelected
        let isDenied = false
        if (status === 'free') {
          if (!isUserSelected) {
            isDenied = true
          }
        } else if (status === 'closed') {
          isDenied = true
        }
        return isDenied
      }
    },
    isThisSelected () {
      return function (timeTableID) {
        const currentSelectedTimeBlock = this.$store.state.table.selectedTimeTableID
        let isThisSelected = false
        if (Number(currentSelectedTimeBlock) === Number(timeTableID)) {
          isThisSelected = true
        }
        return isThisSelected
      }
    },
    currentSubjectTypeID () {
      return this.$store.state.table.currentSubjectTypeID
    },
    currentTableDate () {
      return this.$store.state.table.currentTableDate
    },
    classTableColNumber () {
      if (this.$store.state.table.subjectType.hasOwnProperty(this.$store.state.table.currentSubjectTypeID)) {
        return 'small-up-' + this.$store.state.table.subjectType[this.$store.state.table.currentSubjectTypeID].subjects.length
      }
    },
    styleTableContent () {
      return {
        height: this.tableContentHeight + 'vh',
        width: '100%',
      }
    },
    styleUnitHeight () {
      return {
        height: this.hourHeightUnitVh + 'vh',
      }
    },
    stylePeriod () {
      return function (hour) {
        if (this.currentTable.hasOwnProperty('periods')) {
          const periods = this.currentTable.periods
          let color = 'FFF'
          for (let i = 0; i < periods.length; i++) {
            if (parseInt(hour) >= parseInt(periods[i].firstHour) &&
              parseInt(hour) < parseInt(periods[i].lastHour)) {
              color = periods[i].color
            }
          }

          return {
            background: '#' + color,
            height: this.hourHeightUnitVh + 'vh',
          }
        }
      }
    },
    currentTable () {
      if (this.$store.state.table.hasOwnProperty('subjectType')) {
        if (this.$store.state.table.subjectType.hasOwnProperty(this.$store.state.table.currentSubjectTypeID)) {
          const curTable = this.$store.state.table.subjectType[this.$store.state.table.currentSubjectTypeID]
          this.curTableData = { firstHour: curTable.firstHour, lastHour: curTable.lastHour }
          return curTable
        } else {
          return { subjects: [] }
        }
      } else {
        return { subjects: [] }
      }
    },
    hourHeightUnitVh () {
      const startTime = this.curTableData.firstHour
      const endTime = this.curTableData.lastHour
      return (this.tableContentHeight / (endTime - startTime))
    },
    hourArray () {
      const hours = []
      for (let i = this.curTableData.firstHour; i <= this.curTableData.lastHour - 1; i++) {
        hours.push(i + ':00')
      }
      return hours
    },
  },
  created: function () {
  },
  methods: {
    decreaseCurrentDay () {
      const currentTableDate = this.currentTableDate
      const newTableDate = moment(currentTableDate).subtract(1, 'day')

      console.log(currentTableDate)
      console.log(newTableDate)

      this.updateCurrentTableDate(newTableDate)
    },
    increaseCurrentDay () {
      const currentTableDate = this.currentTableDate
      const newTableDate = moment(currentTableDate).add(1, 'day')

      console.log(currentTableDate)
      console.log(newTableDate)

      this.updateCurrentTableDate(newTableDate)
    },
    getNameOfCurrentDay () {
      if (this.currentTableDate) {
        return moment(this.currentTableDate, 'YYYY-MM-DD', 'hu').format('dddd')
      } else {
        return ''
      }
    },
    selectSubjectType (newSubjectTypeID) {
      console.log('--- Change selected subject type to: ' + newSubjectTypeID)
      this.$store.dispatch('updateSubjectTypeID', { newSubjectTypeID: newSubjectTypeID })
    },
    fetchTable: function (newTableDate) {
      this.$store.dispatch('fetchTableByDate', { date: newTableDate })
    },
    updateCurrentTableDate (newTableDate) {
      this.fetchTable(moment(newTableDate).format('YYYY-MM-DD'))
    },
    timeBlockClick (timeBlock, subjectID) {
      console.log('--- timeBlock: ')
      console.log(timeBlock)

      const reservationData = {
        status: timeBlock.status,
        startTime: timeBlock.time.start,
        endTime: timeBlock.time.end,
        length: timeBlock.time.length,
        subjectID: subjectID,
      }
      // if it's a reservation we have more data so add it
      if (timeBlock.status === 'reserved') {
        reservationData.timeTableID = timeBlock.timeTableID
        reservationData.periodID = timeBlock.periodID
        reservationData.subjectTypeID = timeBlock.subjectTypeID
        reservationData.isPermanent = timeBlock.isPermanent === 1
        reservationData.participants = timeBlock.participants
        reservationData.ticketID = timeBlock.ticketID
      }
      // open modal
      // Free
      if (timeBlock.status === 'free') {
        // Need Selected User
        if (this.$store.state.guest.isSelected) {
          this.$modal.show('newReservationModal', { reservation: reservationData })
          // ...
        }
        // Reserved
      } else if (timeBlock.status === 'reserved') {
        // Select the quest who reserved this time
        this.$store.dispatch('fetchGuestByID', { reqUserID: timeBlock.guest.ID }).then(() => {
          this.$modal.show('editReservationModal', { reservation: reservationData })
        })
        this.$store.state.table.selectedTimeTableID = timeBlock.timeTableID
        // Attended
      } else if (timeBlock.status === 'attended') {
        // this.selectUser(timeBlock.guest.ID)
        // Select the quest who reserved this time
        this.$store.dispatch('fetchGuestByID', { reqUserID: timeBlock.guest.ID }).then(() => {
          this.$store.dispatch('fetchTimeBlockHistory', { subjectID: subjectID, startDate: timeBlock.time.start })
            .then(() => {
              this.$modal.show('attendedModal', { reservation: reservationData })
            })
        })
        this.$store.state.table.selectedTimeTableID = timeBlock.timeTableID
      } else {
        // Closed, attended
        // ...
      }
    },
    getFormattedTime (startTime, endTime) {
      return '' + moment(startTime).format('HH:mm') + '-' + moment(endTime).format('HH:mm')
    },
    getTimeBlockStyle (time, status) {
      const hour = moment(time.start).format('HH:mm')
      const length = time.length
      const timeBlockHeight = this.hourHeightUnitVh * (length / 60)
      const style = {
        background: '#FFF',
        height: timeBlockHeight + 'vh',
      }

      let periodColor = '#FFF'
      if (this.currentTable.hasOwnProperty('periods')) {
        const periods = this.currentTable.periods
        for (let i = 0; i < periods.length; i++) {
          if (parseInt(hour) >= parseInt(periods[i].firstHour) &&
            parseInt(hour) < parseInt(periods[i].lastHour)) {
            periodColor = '#' + periods[i].color
          }
        }
      }

      if (status === 'free') {
        style.background = '#FFF'
      } else if (status === 'closed') {
        style.background = 'rgb(251, 215, 91)'
      } else if (status === 'reserved') {
        style.background = 'repeating-linear-gradient(45deg, #fff, #fff 10px, ' +
          periodColor + ' 10px, ' + periodColor + ' 20px'
      } else if (status === 'attended') {
        style.background = periodColor
      }

      return style
    },
    timeBlockState: timeBlockState,
    getReservationName (timeBlock) {
      let name = false
      if (timeBlock.hasOwnProperty('guest')) {
        if (timeBlock.guest.hasOwnProperty('name')) {
          name = timeBlock.guest.name
        }
      }
      return name
    },
  },
}

function timeBlockState () {
  function isReserved (timeBlock) {
    return timeBlock.status === 'reserved'
  }

  function isFree (timeBlock) {
    return timeBlock.status === 'free'
  }

  function isClosed (timeBlock) {
    return timeBlock.status === 'closed'
  }

  function isPermanent (timeBlock) {
    let isPermanent = false
    if (timeBlock.hasOwnProperty('isPermanent')) {
      isPermanent = timeBlock.isPermanent
    }
    return isPermanent
  }

  function isTrainer (timeBlock) {
    let isTrainer = false
    if (timeBlock.hasOwnProperty('guest')) {
      if (timeBlock.guest.hasOwnProperty('type')) {
        isTrainer = timeBlock.guest.type === 'trainer'
      }
    }
    return isTrainer
  }

  function isGuest (timeBlock) {
    let isGuest = false
    if (timeBlock.hasOwnProperty('guest')) {
      if (timeBlock.guest.hasOwnProperty('type')) {
        isGuest = timeBlock.guest.type === 'guest'
      }
    }
    return isGuest
  }

  function isTicket (timeBlock) {
    let isTicket = false
    if (timeBlock.hasOwnProperty('usedItemType')) {
      isTicket = timeBlock.usedItemType === 'ticket'
    }
    return isTicket
  }

  function isPass (timeBlock) {
    let isPass = false
    if (timeBlock.hasOwnProperty('usedItemType')) {
      isPass = timeBlock.usedItemType === 'pass'
    }
    return isPass
  }

  return {
    isReserved: isReserved,
    isFree: isFree,
    isClosed: isClosed,
    isPermanent: isPermanent,
    isTrainer: isTrainer,
    isGuest: isGuest,
    isTicket: isTicket,
    isPass: isPass,
  }
}

</script>

<style scoped>
ul {
  display: inline-block;
  list-style: none;
}

#timetable {
  height: 100vh;
  padding: 0.5rem
}

.timetable-header-block {
  width: 100%;
}

.timeBlock {
  cursor: pointer;
  font-style: italic;
  overflow: auto;
  line-height: 1;
  border-bottom: 1px solid #ddd;
}

.statusIcon {
  font-size: 200%;
}

.subject-col {
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.deniedAction {
  cursor: not-allowed;
}

h5 > i {
  cursor: pointer;
}

.selected-timeblock {
  border: 1px solid red;
}
</style>
