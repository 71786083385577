import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/views/Login.vue'
import Reservation from '../components/views/reservation/Reservation.vue'
import Close from '../components/views/close/Close.vue'
import Diary from '../components/views/diary/Diary.vue'
import Till from '../components/views/till/Till.vue'
import DailyReport from '../components/views/report/DailyReport'
import Statistic from '../components/views/statistic/Statistic'
import Test from '../components/Test.vue'
import Maintenance from '../components/views/maintenance/Maintenance'
import {authRedirect} from '../processors/auth'
import Rules from '../components/views/rules/Rules.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      beforeEnter: authRedirect,
      component: Login,
    }, {
      path: '/',
      name: 'Reservation',
      beforeEnter: authRedirect,
      component: Reservation,
    }, {
      path: '/close',
      name: 'Close',
      beforeEnter: authRedirect,
      component: Close,
    }, {
      path: '/rules',
      name: 'Rules',
      beforeEnter: authRedirect,
      component: Rules,
    }, {
      path: '/diary',
      name: 'Diary',
      beforeEnter: authRedirect,
      component: Diary,
    }, {
      path: '/till',
      name: 'Till',
      beforeEnter: authRedirect,
      component: Till,
    }, {
      path: '/report',
      name: 'Report',
      beforeEnter: authRedirect,
      component: DailyReport,
    }, {
      path: '/statistic',
      name: 'Statistic',
      beforeEnter: authRedirect,
      component: Statistic,
    }, {
      path: '/maintenance',
      name: 'Maintenance',
      // beforeEnter: authRedirect,
      component: Maintenance,
    }, {
      path: '/test',
      name: 'Test',
      beforeEnter: authRedirect,
      component: Test,
    }, {
      path: '*',
      redirect: '/',
    },
  ],
})

export default router
