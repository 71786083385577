<template>
  <div class="">
    <div class="row expanded collapse">
      <div class="small-1 columns">
        <left-menu/>
      </div>
      <div class="small-3 columns text-center">
        <section>
          <user-actions/>
          <user-auto-complete-search/>
          <user-details v-if="isUserSelected"/>
          <user-reservation-table
            v-if="isUserSelected"
            class="box-border"
          />
          <user-pass-table
            v-if="isUserSelected"
            class="box-border"
          />
          <no-user-selected v-if="!isUserSelected"/>
        </section>
      </div>
      <div class="small-8 columns">
        <schedule-style-one v-if="isNewStyle"/>
        <schedule-style-two v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../left_menu/LeftMenu.vue'
import UserActions from './components/userActions/UserActions.vue'
import UserDetails from './components/UserDetails.vue'
import UserPassTable from './components/UserPassTable.vue'
import UserReservationTable from './components/UserReservationTable.vue'
import ScheduleStyleOne from './components/schedule/ScheduleStyleOne.vue'
import ScheduleStyleTwo from './components/schedule/ScheduleStyleTwo.vue'
import UserAutoCompleteSearch from './components/UserAutoCompleteSearch.vue'
import NoUserSelected from './components/NoUserSelected.vue'

export default {
  components: {
    LeftMenu,
    UserActions,
    UserDetails,
    UserPassTable,
    UserReservationTable,
    UserAutoCompleteSearch,
    NoUserSelected,
    ScheduleStyleOne,
    ScheduleStyleTwo,
  },
  computed: {
    isUserSelected () {
      return this.$store.state.guest.isSelected
    },
    isNewStyle () {
      return false
    },
  },
}
</script>

<style scoped>
</style>
