import swal from 'sweetalert'

function error (msg) {
  swal(msg, {
    title: 'Hiba',
    icon: 'error',
    buttons: {
      confirm: {
        text: 'OK',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
  })
}

function info (msg) {
  swal(msg, {
    icon: 'info',
    buttons: {
      confirm: {
        text: 'OK',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
  })
}

function success (msg) {
  swal(msg, {
    icon: 'success',
    buttons: {
      confirm: {
        text: 'OK',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
  })
}

function warning (msg) {
  swal(msg, {
    icon: 'warning',
    buttons: {
      confirm: {
        text: 'OK',
        value: true,
        visible: true,
        className: '',
        closeModal: true,
      },
    },
  })
}

function confirmation (msg) {
  return new Promise((resolve, reject) => {
    swal(msg, {
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Rendben',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
        cancel: {
          text: 'Mégse',
          value: false,
          visible: true,
          className: 'warning',
          closeModal: true,
        },
      },
    }).then(resp => {
      if (resp) {
        resolve()
      } else {
        reject()
      }
    })
  })

  /*
  swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    confirmButtonClass: '',
    cancelButtonClass: '',
    buttonsStyling: false
  }).then(function () {
    swal(
      'Deleted!',
      'Your file has been deleted.',
      'success'
    )
  }, function (dismiss) {
    // dismiss can be 'cancel', 'overlay',
    // 'close', and 'timer'
    if (dismiss === 'cancel') {
      swal(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
  /*
  return new Promise((resolve, reject) => {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false
    }).then(function () {
      swal(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
      resolve()
    }, function (dismiss) {
      // dismiss can be 'cancel', 'overlay',
      // 'close', and 'timer'
      if (dismiss === 'cancel') {
        swal(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
      reject()
    })
  })
  */
}

export default {
  error: error,
  info: info,
  success: success,
  warning: warning,
  confirmation: confirmation,
}
